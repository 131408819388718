import styled from 'styled-components';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu/menu';
import { Colors } from '../../theme/colors';

export const LogoContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

export const Logo = styled.img`
  width: 75px;
  height: auto;
`

export const CustomMenu = styled(Menu)<MenuProps>`
  background-color: ${Colors.primary};

  .ant-menu-submenu-arrow,
  .anticon > svg {
    color: ${Colors.tertiary};
  }

  .ant-menu-title-content {
    color: ${Colors.secondary};
  }
`
