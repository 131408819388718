import { memo } from "react"
import { Select, Table } from "antd"
import { FaFilter } from "react-icons/fa"
import { AppLayout } from "../.."
import * as S from "./accelerators.module"
import { acceleratorsUsecase } from "./accelerators.usecase"
import type { ColumnsType } from "antd/es/table"
//import { ConsolidatedTable } from "../../../components/table"
import {
  IAcceleratorNewSignUps,
  IAcceleratorTotalProgram,
} from "../../../store/totalizer/interfaces"

function Accelerators() {
  const {
    loading,
    data,
    handleFilter,
    totalizerLoading,
    //dataExtraction,
    handleChange,
    totalByAcceleratorMap,
    acceleratorNewSignUp,
    acceleratorTotalProgram,
  } = acceleratorsUsecase()

  const columns: ColumnsType<IAcceleratorTotalProgram> = [
    {
      title: "Regional",
      dataIndex: "Regional",
      align: "left",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < acceleratorTotalProgram?.length; i++) {
          total += acceleratorTotalProgram[i].CadastroSiteEfetuado
        }
        return (
          <S.Col $align="center">
            <S.Text>{total}</S.Text>
            <S.Text>CADASTROS NO PROGRAMA</S.Text>
          </S.Col>
        )
      },
      dataIndex: "CadastroSiteEfetuado",
      align: "center",
    },
    {
      title: () => {
        let totalAmount = 0
        for (let i = 0; i < acceleratorTotalProgram?.length; i++) {
          totalAmount +=
            acceleratorTotalProgram[i].CadastroSiteEfetuadoMetaAtingida
        }

        // comentario teste build

        if (Number.isNaN(totalAmount)) totalAmount = 0

        return (
          <S.Col $align="center">
            <S.Text>{totalAmount}</S.Text>
            <S.Text>BATERAM META</S.Text>
          </S.Col>
        )
      },
      dataIndex: "CadastroSiteEfetuadoMetaAtingida",
      align: "center",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < acceleratorTotalProgram?.length; i++) {
          total +=
            acceleratorTotalProgram[i].CadastroSiteEfetuadoMetaNaoAtingida
        }
        return (
          <S.Col $align="center">
            <S.Text>{total}</S.Text>
            <S.Text>NÃO BATERAM META</S.Text>
          </S.Col>
        )
      },
      dataIndex: "CadastroSiteEfetuadoMetaNaoAtingida",
      align: "center",
    },
  ]

  const totalByAccelerator: ColumnsType<{
    name: string
    metaAtingida: number
    metaNaoAtingida: number
  }> = [
    {
      title: "ACELERADOR",
      dataIndex: "name",
    },
    {
      title: "META ATINGIDA",
      dataIndex: "metaAtingida",
      align: "center",
    },
    {
      title: "META NÃO ATINGIDA",
      dataIndex: "metaNaoAtingida",
      align: "center",
    },
  ]

  const columnsNewSignups: ColumnsType<IAcceleratorNewSignUps> = [
    {
      title: "Mês",
      dataIndex: "mes",
    },
    {
      title: "Total",
      dataIndex: "TotalNovosCadastros",
    },
  ]

  return (
    <AppLayout
      selectedKeys={["2", "2.3"]}
      title={["Totalizadores", "Aceleradores"]}
    >
      <S.Wrapper $marginBottom="30px">
        <Select
          onChange={handleChange}
          options={data}
          style={{ width: 200 }}
          placeholder="Selecione um período"
        />
        <S.CustomButton
          type="primary"
          icon={<FaFilter />}
          disabled={!data || loading}
          onClick={handleFilter}
        >
          Filtrar
        </S.CustomButton>
        {/* <S.CustomButton type="primary" icon={<FaCog />}>
          Gerar nova base consolidada
        </S.CustomButton> */}
      </S.Wrapper>
      <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <S.Col $gap="15px">
          <Table
            bordered
            caption={
              <S.Col
                style={{
                  alignItems: "flex-start",
                  backgroundColor: "var(--color-primary)",
                  padding: "0 10px",
                }}
              >
                <S.Title style={{ color: "#fff" }}>Total Programa</S.Title>
                <S.Text style={{ color: "#fff" }}>
                  Total Clientes Cadastrados
                </S.Text>
              </S.Col>
            }
            columns={columns}
            dataSource={acceleratorTotalProgram}
            loading={totalizerLoading}
            style={{ flex: 1, minWidth: 250 }}
            pagination={false}
          />
          <S.Row>
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>
                    Total por Acelerador
                  </S.Title>
                  <S.Text style={{ color: "#fff" }}>Total Geral</S.Text>
                </S.Col>
              }
              columns={totalByAccelerator}
              dataSource={totalByAcceleratorMap}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>Novos cadastros</S.Title>
                  <S.Text style={{ color: "#fff" }}>
                    Total Novos Cadastros por Mes
                  </S.Text>
                </S.Col>
              }
              columns={columnsNewSignups}
              dataSource={acceleratorNewSignUp}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
          </S.Row>
        </S.Col>
      </S.Wrapper>
      {/* <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <ConsolidatedTable data={dataExtraction} loading={loading} />
      </S.Wrapper> */}
    </AppLayout>
  )
}

export default memo(Accelerators)
