import { memo } from "react"
import { FaCog, FaFilter } from "react-icons/fa"
import { Typography, Form, Select, Modal, Button } from "antd"
import { AppLayout } from ".."
import * as S from "./base-goal.module"
import { baseGoalUsecase } from "./base-goal.usecase"
import { ConsolidatedTable } from "../../components/table"

function BaseGoal() {
  const {
    loading,
    loadingData,
    downloadReport,
    reportOptions,
    handleChange,
    handleFilter,
    data,
    handleCardConsolidatedReport,
    openModal,
    setOpenModal,
    selectedReport,
    handleCancel,
    handleConfirmGeneration,
    base,
    downloading,
  } = baseGoalUsecase()

  return (
    <AppLayout
      selectedKeys={["1", "1.2"]}
      title={["Exportar Bases", "Base Meta Pontuação"]}
    >
      <S.Wrapper $flexDirection="column" $marginBottom="30px">
        <Typography style={{ fontSize: 16, fontWeight: "700" }}>
          Base Meta Pontuação
        </Typography>
        <Form layout="inline">
          <Form.Item label="Selecione o relatório">
            <Select
              placeholder="Selecione"
              style={{ width: 300 }}
              options={reportOptions}
              onChange={handleChange}
              value={selectedReport?.Id}
            />
          </Form.Item>
          <S.CustomButton
            type="primary"
            icon={<FaFilter />}
            disabled={!data || loading}
            onClick={handleFilter}
            style={{ marginRight: 10 }}
          >
            Filtrar
          </S.CustomButton>
          <Form.Item>
            <S.CustomButton
              type="primary"
              icon={<FaCog />}
              onClick={() =>
                handleCardConsolidatedReport("_base_meta_pontuacao.xlsx")
              }
            >
              Gerar novo arquivo para download
            </S.CustomButton>
          </Form.Item>
        </Form>
      </S.Wrapper>
      <S.Wrapper $alignItems="center" $justifyContent="center">
        <ConsolidatedTable
          data={base}
          loading={loadingData}
          onClickDownload={downloadReport}
          downloading={downloading}
        />
      </S.Wrapper>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        title={"Relatório selecionado está ATIVO"}
        footer={() => (
          <>
            <Button type="default" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleConfirmGeneration(selectedReport)
              }}
            >
              Continuar
            </Button>
          </>
        )}
        destroyOnClose
      >
        <Typography>
          Ao seguir com a geração de nova base, o atual relatório será
          inativado.
        </Typography>
        <Typography>Deseja prosseguir?</Typography>
      </Modal>
    </AppLayout>
  )
}

export default memo(BaseGoal)
