import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
  getDataExtract,
  getRelatorioHistorico,
} from "../../store/totalizer/model"
import api from "../../services/base.service"
import { IExtractDataReport } from "../../components/table/interfaces"
import { IDataExtraction } from "../../store/totalizer/interfaces"
import { setMessage } from "../../store/message/message.slice"
import { updateStatus } from "../../store/totalizer/totatilzer.slice"
import { addDays, format, parseISO } from "date-fns"
import { patchReports } from "../../store/home/model"
import { dateFormatted } from "../../utils/date.utils"

export function linkSellerCustomerUsecase() {
  const dispatch = useAppDispatch()

  const { data: authData } = useAppSelector((state) => state.auth)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loadingGeneration, setLoadingGeneration] = useState<boolean>(false)
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<number>(0)

  const [base, setBase] = useState<IDataExtraction[]>([])

  const [selectedReport, setSelectedReport] = useState<
    IDataExtraction | undefined
  >()

  const { dataRelatorioHistorico } = useAppSelector((state) => state.totalizer)

  const {
    loading: totalizerLoading,
    loading,
    dataExtraction,
  } = useAppSelector((state) => state.totalizer)

  const [IdRelatorioHistorico, setIdRelatorioHistorico] = useState<number>(0)

  const handleChange = useCallback((value: number) => {
    setIdRelatorioHistorico(value)
  }, [])

  const handleFilter = useCallback(async () => {
    // dispatch(getCardTotalizerData({ IdRelatorioHistorico }))
    try {
      setLoadingData(true)
      const response = await api.get("/report/consolidated", {
        params: {
          relatorioBaseTipo: 20,
          IdRelatorioHistorico,
        },
      })

      setBase(response.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }, [IdRelatorioHistorico])

  const reportOptions = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.NomeRelatorioTipo} - ${format(
        addDays(parseISO(item.DataInicio), 1),
        "yyyy-MM-dd",
      )} a ${format(addDays(parseISO(item.DataCorte), 1), "yyyy-MM-dd")}`,
    }))
  }, [dataExtraction])

  useEffect(() => {
    const bootstrap = () => {
      dispatch(getRelatorioHistorico({}))
    }

    return () => {
      bootstrap()
    }
  }, [])

  const handleCardConsolidatedReport = useCallback(
    (reportName: string) => {
      if (IdRelatorioHistorico === 0) return

      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const fileName = `${year}${month}${day}${hours}${minutes}` + reportName

      dispatch(
        patchReports({
          relatorioBaseTipo: 20,
          IdRelatorioHistorico,
          userId: authData?.userId,
          fileName,
        }),
      )
      setTimeout(() => handleFilter(), 2000)
    },
    [dispatch, IdRelatorioHistorico, authData?.userId, handleFilter],
  )

  const downloadReport = useCallback(
    async (item: IExtractDataReport) => {
      try {
        setDownloading(item.Id)
        api.get(`client/customer-seller-link`, {
          params: {
            IdRelatorioHistorico: item.IdRelatorioHistorico,
            nomeArquivo: item.Arquivo,
            userId: authData?.userId,
          },
        })

        dispatch(
          setMessage({
            message:
              "O arquivo será processado em background. Quando estiver pronto será enviado automaticamente.",
            type: "info",
          }),
        )
      } catch (error) {
        console.log(error)
      } finally {
        setDownloading(0)
      }
    },
    [dispatch, authData?.userId],
  )

  const handleUpdateStatus = useCallback(
    (id: number, Ativo: number) => {
      dispatch(updateStatus({ id, Ativo }))
    },
    [dispatch],
  )

  const handleUpdateReportStatus = useCallback(
    async (item: IDataExtraction) => {
      let Ativo = 0

      if (item.Ativo === 1) Ativo = 0
      if (item.Ativo === 0) Ativo = 1

      try {
        setLoadingUpdate(true)
        const response = await api.patch(`report/generated-report/${item.Id}`, {
          Ativo,
        })
        if (response.data) handleUpdateStatus(item.Id, Ativo)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingUpdate(false)
      }
    },
    [handleUpdateStatus, dispatch],
  )

  const handleConfirmGeneration = useCallback(
    async (selectedReport) => {
      if (!selectedReport?.Id)
        return dispatch(
          setMessage({
            message: "Selecione um relatório",
            type: "warning",
          }),
        )

      try {
        setLoadingGeneration(true)

        //await handleUpdateReportStatus() original
        await handleUpdateReportStatus(selectedReport)

        const response = await api.post("client/client-participant", {
          IdRelatorioHistorico: selectedReport?.IdRelatorioHistorico.toString(),
          AnoMes: selectedReport?.MesReferencia,
        })

        if (response.data) {
          dispatch(
            setMessage({
              message: "Consolidado gerado com sucesso",
              type: "success",
            }),
          )
          dispatch(getDataExtract({ relatorioBaseTipo: 1 }))
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingGeneration(false)
      }
    },
    [dispatch],
  )

  const handleCancel = useCallback(() => {
    setSelectedReport(undefined)
    setOpenModal(false)
  }, [])

  const handleGenerate = useCallback(() => {
    if (selectedReport?.Ativo) return setOpenModal(true)

    handleConfirmGeneration(selectedReport)
  }, [selectedReport, handleConfirmGeneration])

  const data = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.Id} ${item.NomeRelatorioTipo} (${dateFormatted(
        item.DataInicio,
        "dd/MM/yyyy",
      )} a ${dateFormatted(item.DataCorte, "dd/MM/yyyy")})`,
    }))
  }, [dataRelatorioHistorico])

  return {
    handleChange,
    handleFilter,
    data,
    loading,
    totalizerLoading,
    dataExtraction,
    downloadReport,
    reportOptions,
    openModal,
    setOpenModal,
    loadingGeneration,
    selectedReport,
    handleCancel,
    handleConfirmGeneration,
    loadingUpdate,
    handleGenerate,
    handleCardConsolidatedReport,
    loadingData,
    base,
    downloading,
  }
}
