import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../services/base.service"
import { IAuthRequest, IAuthResponse } from "./interfaces"

export const authenticate = createAsyncThunk<IAuthResponse, IAuthRequest>(
  "auth/signin",
  async (data) => {
    const response = await api.post<IAuthResponse>("/auth", data)
    return response.data
  },
)
