import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { ConfigProvider } from "antd"
import ptBR from "antd/locale/pt_BR"
import { RouterProvider } from "react-router-dom"
import { store, persistor } from "./store/store"
import router from "./App"

import "react-perfect-scrollbar/dist/css/styles.css"
import config from "./theme/theme"
import { GlobalStyle } from "./global-styles/global"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider direction="ltr" locale={ptBR} theme={config}>
          <RouterProvider router={router} />
          <GlobalStyle />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
