import { Button } from "antd"
import styled from "styled-components"

export const CustomButton = styled(Button)`
  background-color: var(--color-blue-dark);
  box-shadow: none;
`

export const Wrapper = styled.section<{
  $marginBottom?: string
  $alignItems?: string
  $justifyContent?: string
}>`
  display: flex;
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : "flex-start"};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : "flex-start"};
  width: 100%;
  padding: 10px;
  ${(props) => props.$marginBottom && `margin-bottom: ${props.$marginBottom};`};
  background-color: var(--color-white);
  gap: 30px;
`
