import { useCallback, useEffect, useState } from "react"
import { io, Socket } from "socket.io-client"
import axios, { AxiosRequestConfig } from "axios"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setMessage } from "../../store/message/message.slice"

export function EventsGatway() {
  const dispatch = useAppDispatch()

  const { data: authData } = useAppSelector((state) => state.auth)

  const [, setSocket] = useState<Socket | null>(null)

  const downloadFile = useCallback(
    async (data: { downloadUrl: string; fileName: string }) => {
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: data.downloadUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData?.token}`,
          },
          responseType: 'blob'
        }
        // Fazendo a requisição com autenticação
        const response = await axios.request(config)

        // Convertendo a resposta para blob (necessário para o download)
        const blob = new Blob([response?.data]);

        // Criando o link de download manualmente
        const link = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = data.fileName
        document.body.appendChild(link)
        link.click()

        // Limpando o link criado
        window.URL.revokeObjectURL(url)
        link.remove()

        // Mensagem de sucesso
        dispatch(
          setMessage({
            message: "O arquivo está finalizado e processando o download.",
            type: "success",
          }),
        )
      } catch (error) {
        console.error("Erro ao baixar o arquivo:", error)
        dispatch(
          setMessage({
            message: "Erro ao processar o download do arquivo.",
            type: "error",
          }),
        )
      }
    },
    [authData?.token, dispatch],
  )

  useEffect(() => {
    if (!authData?.userId) return

    const userId = authData?.userId

    const SOCKET_URL = "https://vipizz-hml.omegacotech.com.br/reports"

    // Conectar ao WebSocket e passar o userId na query string
    const socketConnection = io(SOCKET_URL, {
      transports: ["polling"],
      query: { userId },
      path: "/socket.io",
    })

    // Salvar a conexão no estado
    setSocket(socketConnection)

    // Listener para receber mensagens do servidor
    socketConnection.on(
      "sendReport",
      (data: { downloadUrl: string; fileName: string }) => {
        // const link = document.createElement("a")
        // link.href = data.downloadUrl
        // link.download = data.fileName
        // link.click()
        downloadFile(data)
        dispatch(
          setMessage({
            message: "O arquivo está finalizado e processando o download.",
            type: "success",
          }),
        )
      },
    )

    // Limpar a conexão ao desmontar o componente
    return () => {
      socketConnection.disconnect()
    }
  }, [authData?.userId])

  return null
}
