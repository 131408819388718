import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authenticate } from '../../store/auth/model';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { clearMessage } from '../../store/message/message.slice';

export function signinUsecase() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data, loading, isLoggedIn } = useAppSelector(state => state.auth)
  const { message, type } = useAppSelector((state) => state.message)

  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (message) {
      api.open({
        message: message,
        type: type,
        duration: 5,
        onClose() {
          dispatch(clearMessage())
        },
      })
    }
  }, [message])

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/totalizadores/card', { replace: true })
    } else {
      navigate('/', { replace: true })
    }
  }, [isLoggedIn])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = useCallback((values: any) => {
    values.celphone = values.celphone.replace("(", "").replace(")", "").replace(" ", "")
    dispatch(authenticate(values))
  }, [dispatch])

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  return {
    onFinish,
    onFinishFailed,
    data,
    loading,
    contextHolder
  }
}
