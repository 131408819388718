/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from "react"
import { Helmet } from "react-helmet"
import InputMask from "react-input-mask"
import * as S from "./signin.module"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { Form, Input } from "antd"
import { signinUsecase } from "./signin.usecase"
import Logo from "../../assets/images/logo.png"

type FieldType = {
  celphone?: string
  senha?: string
}

function SingIn() {
  const { onFinish, onFinishFailed, loading, contextHolder } = signinUsecase()

  return (
    <S.Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VIPZZ Admin ETL | Login</title>
        <link
          href="https://fonts.cdnfonts.com/css/helvetica-neue-55"
          rel="stylesheet"
        />
      </Helmet>
      <S.NeonContainerWrapper>
        {contextHolder}
        <S.LogoContainer>
          <S.Logo src={Logo} alt="logo" />
        </S.LogoContainer>
        <S.CustomForm
          name="normal_login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onSubmitCapture={(e) => e.preventDefault()}
          autoComplete="off"
          layout="vertical"
          className="login-form"
        >
          <Form.Item<FieldType>
            label="Celular"
            name="celphone"
            rules={[{ required: true, message: "Informe seu celular!" }]}
            style={{ width: "100%" }}
          >
            <InputMask
              mask="(99) 99999-9999"
              placeholder="(00) 91234-1234"
              alwaysShowMask={false}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                style={{ width: "100%" }}
              />
            </InputMask>
          </Form.Item>
          <Form.Item<FieldType>
            label="Senha"
            name="senha"
            rules={[{ required: true, message: "Informe sua senha!" }]}
            style={{ width: "100%" }}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="*******"
            />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <S.CustomButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              Acessar
            </S.CustomButton>
          </Form.Item>
        </S.CustomForm>
      </S.NeonContainerWrapper>
    </S.Container>
  )
}

export default memo(SingIn)
