import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../services/base.service"
import {
  IExtractDataRequest,
  IExtractDataResponse,
  IRelatorioHistoricoRequest,
  IRelatorioHistoricoResponse,
  IResponseAcceleratorNewSignUps,
  IResponseAcceleratorPositiveClients,
  IResponseAcceleratorTargetClients,
  IResponseAcceleratorTotalProgram,
  IResponseAwardDistributedPrizes,
  IResponseAwardPositivationAndTarget,
  IResponseCardTotal,
  IResponseIAveragePrice,
  IResponseKPIPositivationTargetByRegional,
  IResponsePositiveClients,
  IResponsePositiveClientsTargetMonthly,
  IResponsePositiveClientsTargetRanking,
  IResponseTotalByAccelerator,
  ITotalizerRequest,
} from "./interfaces"

export const getCardTotalizerData = createAsyncThunk<
  IResponseCardTotal,
  ITotalizerRequest
>("totalizer/getCard", async (params) => {
  const response = await api.get<IResponseCardTotal>(
    "/report/consolidated/card-total",
    { params },
  )
  return response.data
})

export const getRelatorioHistorico = createAsyncThunk<
  IRelatorioHistoricoResponse,
  IRelatorioHistoricoRequest
>("totalizer/getRelatorioHistorico", async (params) => {
  const response = await api.get<IRelatorioHistoricoResponse>(
    "/report-history/reportHistory",
    {
      params,
    },
  )
  return response.data
})

export const getDataExtract = createAsyncThunk<
  IExtractDataResponse,
  IExtractDataRequest
>("totalizer/getDataExtract", async (params) => {
  const response = await api.get<IExtractDataResponse>(
    "/report/generated-report",
    { params },
  )
  return response.data
})

export const getBaseGoalData = createAsyncThunk<
  IExtractDataResponse,
  ITotalizerRequest
>("totalizer/getBaseGoalData", async (params) => {
  const response = await api.get<IExtractDataResponse>(
    "/client/score-goal",
    { params },
  )
  
  return response.data
})

export const getKPIPositivationAndTarget = createAsyncThunk<
  IResponseKPIPositivationTargetByRegional,
  ITotalizerRequest
>("totalizer/getKPIPositivationAndTarget", async (params) => {
  const response = await api.get<IResponseKPIPositivationTargetByRegional>(
    "/report/consolidated/kpi-positivation-and-target",
    { params },
  )
  return response.data
})

export const getKPIAveragePrice = createAsyncThunk<
  IResponseIAveragePrice,
  ITotalizerRequest
>("totalizer/getKPIAveragePrice", async (params) => {
  const response = await api.get<IResponseIAveragePrice>(
    "/report/consolidated/kpi-average-price",
    { params },
  )
  return response.data
})

export const getKPIAveragePriceTotal = createAsyncThunk<
  IResponseIAveragePrice,
  ITotalizerRequest
>("totalizer/getKPIAveragePriceTotal", async (params) => {
  const response = await api.get<IResponseIAveragePrice>(
    "/report/consolidated/kpi-average-price-total",
    { params },
  )
  return response.data
})

export const getKPIPositivationClient = createAsyncThunk<
  IResponsePositiveClients,
  ITotalizerRequest
>("totalizer/getKPIPositivationClient", async (params) => {
  const response = await api.get<IResponsePositiveClients>(
    "/report/consolidated/kpi-positivation-client",
    { params },
  )
  return response.data
})

export const getKPIPositivationClientTarget = createAsyncThunk<
  IResponsePositiveClientsTargetMonthly,
  ITotalizerRequest
>("totalizer/getKPIPositivationClientTarget", async (params) => {
  const response = await api.get<IResponsePositiveClientsTargetMonthly>(
    "/report/consolidated/kpi-positivation-client-target",
    { params },
  )
  return response.data
})

export const getKPIPositivationClientTargetClassification = createAsyncThunk<
  IResponsePositiveClientsTargetRanking,
  ITotalizerRequest
>("totalizer/getKPIPositivationClientTargetClassification", async (params) => {
  const response = await api.get<IResponsePositiveClientsTargetRanking>(
    "/report/consolidated/kpi-positivation-client-target-classification",
    { params },
  )
  return response.data
})

export const getAcceleratorTotalProgram = createAsyncThunk<
  IResponseAcceleratorTotalProgram,
  ITotalizerRequest
>("totalizer/getAcceleratorTotalProgram", async (params) => {
  const response = await api.get<IResponseAcceleratorTotalProgram>(
    "/report/consolidated/accelerator-total-program",
    { params },
  )
  return response.data
})

export const getAcceleratorTotalAccelerator = createAsyncThunk<
  IResponseTotalByAccelerator,
  ITotalizerRequest
>("totalizer/getAcceleratorTotalAccelerator", async (params) => {
  const response = await api.get<IResponseTotalByAccelerator>(
    "/report/consolidated/total-by-accelerator",
    { params },
  )
  return response.data
})

export const getAcceleratorNewSignUp = createAsyncThunk<
  IResponseAcceleratorNewSignUps,
  ITotalizerRequest
>("totalizer/getAcceleratorNewSignUp", async (params) => {
  const response = await api.get<IResponseAcceleratorNewSignUps>(
    "/report/consolidated/accelerator-new-registers",
    { params },
  )
  return response.data
})

export const getAcceleratorPositiveClients = createAsyncThunk<
  IResponseAcceleratorPositiveClients,
  ITotalizerRequest
>("totalizer/getAcceleratorPositiveClients", async (params) => {
  const response = await api.get<IResponseAcceleratorPositiveClients>(
    "/report/consolidated/accelerator-positive-clients",
    { params },
  )
  return response.data
})

export const getAcceleratorTargetClients = createAsyncThunk<
  IResponseAcceleratorTargetClients,
  ITotalizerRequest
>("totalizer/getAcceleratorTargetClients", async (params) => {
  const response = await api.get<IResponseAcceleratorTargetClients>(
    "/report/consolidated/accelerator-target-clients",
    { params },
  )
  return response.data
})

export const getAwardPositivationAndTarget = createAsyncThunk<
  IResponseAwardPositivationAndTarget,
  ITotalizerRequest
>("totalizer/getAwardPositivationAndTarget", async (params) => {
  const response = await api.get<IResponseAwardPositivationAndTarget>(
    "/report/consolidated/award-positivation-and-target",
    { params },
  )
  return response.data
})

export const getAwardDistributedPrizes = createAsyncThunk<
  IResponseAwardDistributedPrizes,
  ITotalizerRequest
>("totalizer/getAwardDistributedPrizes", async (params) => {
  const response = await api.get<IResponseAwardDistributedPrizes>(
    "/report/consolidated/award-distributed-prizes",
    { params },
  )
  return response.data
})
