import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { notification } from "antd"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import { io, Socket } from "socket.io-client"
import api from "../../services/base.service"
import {
  ICreateReport,
  IExtractDataReportHistory,
  IOption,
  IReport,
  IReportType,
} from "./interfaces"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { getRelatorioHistorico } from "../../store/totalizer/model"

export function insertBaseUsecase() {
  const dispatch = useAppDispatch()

  const [messageApi, contextHolder] = notification.useNotification()

  const { dataRelatorioHistorico: dataExtraction } = useAppSelector(
    (state) => state.totalizer,
  )

  const { data: authData } = useAppSelector((state) => state.auth)

  const [errors, setErrors] = useState({
    IdRelatorioTipo: "",
    MesReferencia: "",
    DataCorte: "",
    DataInicio: "",
  })

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [reportData, setReportData] = useState<ICreateReport>({
    IdRelatorioTipo: 0,
    MesReferencia: "",
    DataCorte: "",
    DataInicio: "",
  })

  const [activeTab, setActiveTab] = useState<string>("1")

  const [enableConsolidation, setEnableConsolidation] = useState<boolean>(false)

  const [importeds, setImporteds] = useState({
    qlik: 0,
    salesTeam: 0,
    siteSignup: 0,
    dateSiteSignup: 0,
    clientVipizz: 0,
    cliven: 0,
    targetVolume: 0,
    rescue: 0,
    salesTeamHierarchy: 0,
    supervisorsTeam: 0,
  })

  const [consolidateLoading, setConsolidateLoading] = useState<boolean>(false)

  const [reportsType, setReportsType] = useState<IOption[]>()
  const [loadingReportsType, setLoadingReportsType] = useState<boolean>(false)

  const [values, setValues] = useState<{ reports: IReport[] }>({
    reports: [
      {
        id: 1,
        label: "Qlik",
        base: "qlik",
        path: "qlik",
        status: "Aguardando arquivo",
        total: 0,
        loading: false,
        file: "",
      },
      // {
      //   id: 2,
      //   label: "Equipe Vendas",
      //   status: "Aguardando arquivo",
      //   loading: false,
      //   file: "",
      // },
      {
        id: 3,
        label: "Cadastro Site",
        base: "siteSignup",
        path: "site-signup",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 4,
        label: "Data Cadastro Cliente",
        base: "dateSiteSignup",
        path: "site-signup-date",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 5,
        label: "Cliente Vipizz",
        base: "clientVipizz",
        path: "client-vipizz",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 6,
        label: "Cliven",
        base: "cliven",
        path: "cliven",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 7,
        label: "Meta Volume",
        base: "targetVolume",
        path: "target",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 8,
        label: "Resgate",
        base: "rescue",
        path: "rescues",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 9,
        label: "Time Vendas Hierarquia",
        base: "salesTeamHierarchy",
        path: "sales-team-hierarchy",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
      {
        id: 10,
        label: "Equipe Supervisores",
        base: "supervisorsTeam",
        path: "supervisor-team",
        status: "Aguardando arquivo",
        loading: false,
        total: 0,
        file: "",
      },
    ],
  })

  const [selectedRow, setSelectedRow] =
    useState<IExtractDataReportHistory | null>(null)

  const [, setSocket] = useState<Socket | null>(null)

  const reports = useCallback(
    (
      id?: number,
      status?: string,
      loading?: boolean,
      file?: string | Blob,
      total?: number,
    ) => {
      if (id) {
        const arr = values.reports.map((item) => {
          if (item.id === id) {
            item.file = file ? file : item.file
            item.status = status ? status : item.status
            item.loading = loading ? loading : item.loading
            item.total = total ? total : item.total
          }
          // console.log(item)
          return item
        })

        setValues({
          ...values,
          reports: arr,
        })
      }
    },
    [values.reports, values],
  )

  useEffect(() => {
    if (!authData?.userId) return

    const userId = authData?.userId

    const SOCKET_URL = "https://vipizz-hml.omegacotech.com.br/bases"

    // Conectar ao WebSocket e passar o userId na query string
    const socketConnection = io(SOCKET_URL, {
      transports: ["polling"],
      query: { userId },
      path: "/socket.io",
    })

    // Salvar a conexão no estado
    setSocket(socketConnection)

    // Listener para receber mensagens do servidor
    socketConnection.on(
      "importBase",
      (data: {
        idReport: number
        status: string
        loading: boolean
        message: string,
        total?: number
      }) => {
        if (data.status === "error")
          messageApi.open({
            message: data.message,
            type: "error",
          })
        reports(data.idReport, data.status, data.loading, "", data.total)
      },
    )

    // Limpar a conexão ao desmontar o componente
    return () => {
      socketConnection.disconnect()
    }
  }, [authData?.userId])

  const handleReportStatusResponse = useCallback(
    (report: string, value: number) => {
      const reports = values?.reports?.map((item) => {
        if (item.base === report) {
          item.total = value
        }

        return item
      })

      setValues({ ...values, reports })
    },
    [values],
  )

  const handleReportStatus = useCallback(
    async (report: string, path: string) => {
      try {
        const response = await api.get(`${path}/status`, {
          params: {
            IdRelatorioHistorico: selectedRow?.Id,
          },
        })
        if (response.data) {
          handleReportStatusResponse(report, response.data.data.total)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [selectedRow],
  )

  useEffect(() => {
    const batchRequest = () => {
      Promise.all([
        handleReportStatus("qlik", "qlik"),
        handleReportStatus("salesTeam", "sales-team"),
        handleReportStatus("siteSignup", "site-signup"),
        handleReportStatus("dateSiteSignup", "site-signup-date"),
        handleReportStatus("clientVipizz", "client-vipizz"),
        handleReportStatus("cliven", "cliven"),
        handleReportStatus("targetVolume", "target"),
        handleReportStatus("rescue", "rescues"),
        handleReportStatus("salesTeamHierarchy", "sales-team-hierarchy"),
        handleReportStatus("supervisorsTeam", "supervisor-team"),
      ])
    }
    if (selectedRow) {
      batchRequest()
    }
  }, [selectedRow])

  const handlerBatchSetImportedsValue = useCallback(
    (newValue: number) => {
      setImporteds({
        qlik: newValue,
        salesTeam: newValue,
        siteSignup: newValue,
        dateSiteSignup: newValue,
        clientVipizz: newValue,
        cliven: newValue,
        targetVolume: newValue,
        rescue: newValue,
        salesTeamHierarchy: newValue,
        supervisorsTeam: newValue,
      })
    },
    [importeds],
  )

  const resetImports = useCallback(() => {
    setValues({
      ...values,
      reports: [
        {
          id: 1,
          label: "Qlik",
          base: "qlik",
          path: "qlik",
          status: "Aguardando arquivo",
          total: 0,
          loading: false,
          file: "",
        },
        // {
        //   id: 2,
        //   label: "Equipe Vendas",
        //   status: "Aguardando arquivo",
        //   loading: false,
        //   file: "",
        // },
        {
          id: 3,
          label: "Cadastro Site",
          base: "siteSignup",
          path: "site-signup",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 4,
          label: "Data Cadastro Cliente",
          base: "dateSiteSignup",
          path: "site-signup-date",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 5,
          label: "Cliente Vipizz",
          base: "clientVipizz",
          path: "client-vipizz",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 6,
          label: "Cliven",
          base: "cliven",
          path: "cliven",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 7,
          label: "Meta Volume",
          base: "targetVolume",
          path: "target",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 8,
          label: "Resgate",
          base: "rescue",
          path: "rescues",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 9,
          label: "Time Vendas Hierarquia",
          base: "salesTeamHierarchy",
          path: "sales-team-hierarchy",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
        {
          id: 10,
          label: "Equipe Supervisores",
          base: "supervisorsTeam",
          path: "supervisor-team",
          status: "Aguardando arquivo",
          loading: false,
          total: 0,
          file: "",
        },
      ],
    })
  }, [values])

  const onChangeConsolidate = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`)
    // console.log(e.target.checked)
    setEnableConsolidation(e.target.checked)
  }

  const onSelectChange = (item: IExtractDataReportHistory) => {
    if (selectedRow?.Id === item.Id) {
      handlerBatchSetImportedsValue(0)
      return setSelectedRow(null)
    }

    resetImports()
    handlerBatchSetImportedsValue(1)
    setSelectedRow(item)
  }

  const onChangeTab = (key: string) => {
    setActiveTab(key)
  }

  useEffect(() => {
    const bootstrap = () => {
      dispatch(getRelatorioHistorico({}))
    }

    return () => {
      bootstrap()
    }
  }, [])

  useEffect(() => {
    if (
      importeds.clientVipizz === 1 &&
      importeds.cliven === 1 &&
      importeds.dateSiteSignup === 1 &&
      importeds.qlik === 1 &&
      importeds.rescue === 1 &&
      importeds.salesTeam === 1 &&
      importeds.salesTeamHierarchy === 1 &&
      importeds.siteSignup === 1 &&
      importeds.supervisorsTeam === 1 &&
      importeds.targetVolume === 1
    )
      setCurrentStep(2)
  }, [importeds])

  const handleMountReportsType = useCallback((data: IReportType[]) => {
    return data?.map((item) => ({
      value: item.Id,
      label: item.Nome,
    }))
  }, [])

  const handleInit = useCallback(async () => {
    try {
      setLoadingReportsType(true)
      const response = await api.get(`report-type`)
      if (response?.data?.data) {
        const data = handleMountReportsType(response?.data?.data)
        setReportsType(data)
      } else setReportsType([])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingReportsType(false)
    }
  }, [])

  useEffect(() => {
    const bootstrap = () => {
      handleInit()
    }

    bootstrap()
  }, [])

  const isAvailableGeneration = useMemo(() => {
    let total = 9

    values.reports.forEach((report) => {
      if (report.total > 0) total -= 1
    })

    const disabled = Boolean(total === 0)
    // console.log(total)
    return !disabled
  }, [values.reports, selectedRow])

  // const [messageApi, contextHolder] = messageApi.useMessage()

  const inpuFileRef = useRef<HTMLInputElement | null>(null)

  const handleChangeFile = (
    value: number,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event?.target.files && event?.target.files[0]
    if (file) {
      reports(value, "Aguardando envio", false, file)
    }
  }

  const handleSelect = useCallback(
    (
      value: number,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _option: {
        value: number
        label: string
      },
    ) => {
      setReportData({
        ...reportData,
        IdRelatorioTipo: value,
      })
    },
    [reportData],
  )

  const handleChangeDate = useCallback(
    (property: string, value: string) => {
      if (property === "MesReferencia") value += "-01"
      // if (property === "DataCorte") value += "-30"

      setReportData({
        ...reportData,
        [property]: value,
      })
    },
    [reportData],
  )

  const clearErrors = useCallback(
    (property: string) => {
      setErrors({
        ...errors,
        [property]: "",
      })
    },
    [errors],
  )

  const handleSubimtReport = async () => {
    if (!reportData?.DataCorte) {
      return messageApi.open({
        type: "error",
        message: "Data corte deve ser informada",
      })
    }

    if (!reportData?.DataInicio)
      return messageApi.open({
        type: "error",
        message: "Data início deve ser informada",
      })

    if (!reportData?.MesReferencia)
      return messageApi.open({
        type: "error",
        message: "Mês referência deve ser informado",
      })

    if (!reportData?.IdRelatorioTipo)
      return messageApi.open({
        type: "error",
        message: "Relatório deve ser informado",
      })

    try {
      const response = await api.post("report-history", { ...reportData })
      if (response?.data?.data) {
        setReportData(response?.data?.data)
        setSelectedRow(response?.data?.data)
        setActiveTab("1")
        setCurrentStep(1)
        return messageApi.open({
          type: "success",
          message: "Relatorio criado",
        })
      }

      return
    } catch (error) {
      return messageApi.open({
        type: "error",
        message: "Ocorreu um erro inesperado.",
      })
    } finally {
      dispatch(getRelatorioHistorico({}))
      setReportData({
        IdRelatorioTipo: 0,
        MesReferencia: "",
        DataCorte: "",
        DataInicio: "",
      })
    }
  }

  const handleSubmitFile = async () => {
    // messageApi.open({
    //   type: "loading",
    //   message: "tnc",
    // })
  }

  const reimportReport = (IdRelatorioHistorico: number, importType: string) => {
    if (selectedRow) {
      setImporteds({ ...importeds, [importType]: 1 })
    }

    if (importeds[importType] === 0) {
      setImporteds({ ...importeds, [importType]: 1 })
      return {
        IdRelatorioHistorico,
      }
    }

    return {
      IdRelatorioHistorico,
      reimportar: 1,
    }
  }

  const uploadFile = async (idReport: number, reportId: number) => {
    let params = {}
    let url = ""

    switch (idReport) {
      case 1:
        url = "/qlik/upload"
        params = reimportReport(reportId, "qlik")
        break
      case 2:
        url = "/sales-team/upload"
        params = reimportReport(reportId, "salesTeam")
        break
      case 3:
        url = "/site-signup/upload"
        params = reimportReport(reportId, "siteSignup")
        break
      case 4:
        url = "/site-signup-date/upload"
        params = reimportReport(reportId, "dateSiteSignup")
        break
      case 5:
        url = "/client-vipizz/upload"
        params = reimportReport(reportId, "clientVipizz")
        break
      case 6:
        url = "/cliven/upload"
        params = reimportReport(reportId, "cliven")
        break
      case 7:
        url = "/target/upload"
        params = reimportReport(reportId, "targetVolume")
        break
      case 8:
        url = "/rescues/upload"
        params = reimportReport(reportId, "rescue")
        break
      case 9:
        url = "/sales-team-hierarchy/upload"
        params = reimportReport(reportId, "salesTeamHierarchy")
        break
      case 10:
        url = "/supervisor-team/upload"
        params = reimportReport(reportId, "supervisorTeam")
        break
    }

    try {
      const report = values.reports.find((item) => item.id === idReport)
      if (report) {
        reports(idReport, "processando", true)
        const formData = new FormData()
        formData.append("file", report.file)
        api.post(url, formData, {
          params: {
            ...params,
            userId: authData?.userId,
            idReport,
          },
        })

        messageApi.open({
          type: "info",
          message:
            "O arquivo será processado no servidor. Assim que finalizar será enviada uma mensagem.",
        })

        // if (response.data.data) {
        //   // console.log(response.data.data)
        //   return reports(idReport, "Concluído", false, response.data.data)
        // }
      }

      return messageApi.open({
        type: "warning",
        message: "Nenhuma base foi localizada para importar",
      })
    } catch (error) {
      messageApi.open({
        type: "error",
        message: "Ocorreu um erro ao importar o relatório.",
      })
      return reports(idReport, "erro", false)
    }
  }

  const handleReportSelect = useCallback((report: "selected" | "created") => {
    if (report === "selected")
      setReportData({
        IdRelatorioTipo: 0,
        MesReferencia: "",
        DataCorte: "",
        DataInicio: "",
      })

    if (report === "created") setSelectedRow(null)

    setOpenModal(false)
  }, [])

  const handleUploadFile = async (idReport: number) => {
    if (!reportData?.Id && !selectedRow?.Id)
      return messageApi.open({
        type: "warning",
        message: "Crie ou Selecione um relatório.",
      })

    if (reportData.Id && selectedRow?.Id) return setOpenModal(true)

    const reportId = selectedRow ? selectedRow?.Id : reportData.Id

    if (!reportId || reportId === 0)
      return messageApi.open({
        message: "Selecione um relatório ou crie um novo",
        type: "warning",
      })

    uploadFile(idReport, reportId)
  }

  const handleClientsParticipants = useCallback(async () => {
    setConsolidateLoading(true)
    try {
      if (reportData.Id === 0 && !selectedRow)
        return messageApi.open({
          type: "error",
          message: "Relatório Histórico deve ser criado ou selecionado",
        })

      const reportId = selectedRow?.Id ? selectedRow?.Id : reportData.Id

      const response = await api.post("client/client-participant", {
        IdRelatorioHistorico: reportId?.toString(),
      })

      if (response.data) {
        setCurrentStep(3)
        return messageApi.open({
          type: "success",
          message: "Consolidação de Bases",
          description: "Consolidação de bases realizada com sucesso",
          duration: 0,
        })
      }
    } catch (error) {
      return messageApi.open({
        type: "error",
        message: "Ocorreu um erro ao gerar clientes participantes.",
      })
    } finally {
      setConsolidateLoading(false)
    }
  }, [selectedRow, reportData])

  const renderButtonText = (idReport: number) => {
    switch (idReport) {
      case 1:
        if (importeds.qlik === 1) return "Reenviar"
        return "Enviar"
      case 2:
        if (importeds.salesTeam === 1) return "Reenviar"
        return "Enviar"
      case 3:
        if (importeds.siteSignup === 1) return "Reenviar"
        return "Enviar"
      case 4:
        if (importeds.dateSiteSignup === 1) return "Reenviar"
        return "Enviar"
      case 5:
        if (importeds.clientVipizz === 1) return "Reenviar"
        return "Enviar"
      case 6:
        if (importeds.cliven === 1) return "Reenviar"
        return "Enviar"
      case 7:
        if (importeds.targetVolume === 1) return "Reenviar"
        return "Enviar"
      case 8:
        if (importeds.rescue === 1) return "Reenviar"
        return "Enviar"
        break
      case 9:
        if (importeds.salesTeamHierarchy === 1) return "Reenviar"
        return "Enviar"
      case 10:
        if (importeds.supervisorsTeam === 1) return "Reenviar"
        return "Enviar"
      default:
        return "Enviar"
    }
  }

  const disableCustomUpload = useMemo(() => {
    let disabled = true

    if (reportData?.Id || selectedRow?.Id) disabled = false

    return disabled
  }, [reportData?.Id, selectedRow?.Id])

  const reportDataExtract = useMemo(() => {
    return dataExtraction?.filter((item) => item.Ativo === 1)
  }, [dataExtraction])

  return {
    handleChangeFile,
    handleChangeDate,
    handleSubimtReport,
    errors,
    clearErrors,
    contextHolder,
    handleSelect,
    handleSubmitFile,
    handleUploadFile,
    inpuFileRef,
    reports,
    values,
    reportData,
    isAvailableGeneration,
    handleClientsParticipants,
    loadingReportsType,
    reportsType,
    renderButtonText,
    currentStep,
    onChangeTab,
    onSelectChange,
    selectedRow,
    disableCustomUpload,
    reportDataExtract,
    openModal,
    setOpenModal,
    handleReportSelect,
    handleReportStatus,
    consolidateLoading,
    activeTab,
    onChangeConsolidate,
    enableConsolidation,
  }
}
