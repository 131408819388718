import axios from "axios"
import Cookies from "js-cookie"
import { store } from "../store/store"
import { logout } from "../store/auth/auth.slice"
import { setMessage } from "../store/message/message.slice"

const instance = axios.create({
  baseURL: "https://vipizz-hml.omegacotech.com.br/api",
  // baseURL: "http://localhost:3005/api",
  timeout: 30 * 60 * 1000, // 30 minutos para o timeout - tempo exagerado pensando na geração das bases - refatorar para receber o status por socket ou event?!
  timeoutErrorMessage: "Timeout error",
})

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("session_token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response

    if (status === 401) {
      store.dispatch(logout())
      store.dispatch(
        setMessage({
          message: "Sessão finalizada. Faça login novamente.",
          type: "info",
        }),
      )
    }

    if (status >= 400) {
      error?.response?.data?.errors?.forEach((error) => {
        console.log(error)
        if (typeof error === "string")
          store.dispatch(
            setMessage({
              message: error,
              type: "error",
            }),
          )
        if (typeof error === "object")
          store.dispatch(
            setMessage({
              message: error?.["param"] + " " + error?.["msg"],
              type: "error",
            }),
          )
      })
    }

    return Promise.reject(error.response)
  },
)

export default instance
