import styled from "styled-components"
import { Button, Switch } from "antd"

export const CustomButton = styled(Button)`
  background-color: var(--color-blue-dark);
  box-shadow: none;

  &:hover {
    background-color: var(--color-blue);
  };
`

export const CustomSwitch = styled(Switch)`
  &.ant-switch.ant-switch-checked {
    background: var(--color-blue) !important;
  };
`
export const Col = styled.section<{ $align?: string, $gap?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(props) => props.$gap && `gap: ${props.$gap};`};
  ${(props) => props.$align && `align-items: ${props.$align};`};
`

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
`

export const Text = styled.span``

export const Row = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;

  @media (max-width: 1670px) {
    flex-direction: column;
  }
`

export const Wrapper = styled.section<{
  $marginBottom?: string
  $alignItems?: string
  $justifyContent?: string
}>`
  display: flex;
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : "flex-start"};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : "flex-start"};
  width: 100%;
  padding: 10px;
  ${(props) => props.$marginBottom && `margin-bottom: ${props.$marginBottom};`};
  background-color: var(--color-white);
  gap: 30px;
`
