import styled from "styled-components"
import { Button, Switch } from "antd"

export const CustomButton = styled(Button)`
  background-color: var(--color-blue-dark);
  box-shadow: none;

  &:hover {
    background-color: var(--color-blue);
  }
`

export const CustomSwitch = styled(Switch)`
  &.ant-switch.ant-switch-checked {
    background: var(--color-blue) !important;
  }
`

export const Wrapper = styled.section<{
  $marginBottom?: string
  $alignItems?: string
  $justifyContent?: string
  $flexDirection?: string
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : "inherit"};
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : "flex-start"};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : "flex-start"};
  width: 100%;
  padding: 10px;
  ${(props) => props.$marginBottom && `margin-bottom: ${props.$marginBottom};`};
  background-color: var(--color-white);
  gap: 30px;
`
