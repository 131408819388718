import { memo } from "react"
import { Empty } from "antd"
import { AppLayout } from ".."
import * as S from "./home.module"
import { homeUsecase } from "./home.usecase"

function Home() {
  const {} = homeUsecase()
  return (
    <AppLayout selectedKeys={["0"]} title={["Inicio", "Totais"]}>
      <S.Wrapper $alignItems="center" $justifyContent="center">
        <Empty />
      </S.Wrapper>
    </AppLayout>
  )
}

export default memo(Home)
