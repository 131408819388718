import { useCallback, useState } from "react"
import { Button, Switch, Table } from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { SpinProps } from "antd/lib/spin"
import { format, parseISO } from "date-fns"
import { ptBR } from "date-fns/locale"
import { DownloadOutlined } from "@ant-design/icons"
import { IExtractDataReport } from "./interfaces"
import { useConsolidated } from "./consolidated.usecase"

const CustomSwitch = ({
  item,
  onClick,
}: {
  item: IExtractDataReport
  onClick: (data: IExtractDataReport) => void
}) => {
  const [checked, setChecked] = useState(item.Ativo === 1)

  const handleChange = useCallback(() => {
    setChecked(!checked)
    onClick(item)
  }, [checked, item])

  return (
    <Switch
      checkedChildren="ON"
      unCheckedChildren="OFF"
      checked={checked}
      onChange={handleChange}
      size="small"
    />
  )
}

function ConsolidatedTable({
  data,
  pagination,
  loading,
  onClickDownload,
  downloading,
}: {
  data: IExtractDataReport[]
  pagination?: TablePaginationConfig
  loading?: boolean | SpinProps
  onClickDownload?: (data: IExtractDataReport) => void,
  downloading?: number,
}) {
  const { handleUpdateReportStatus } = useConsolidated()

  const extractDataColumns: ColumnsType<IExtractDataReport> = [
    {
      title: "#",
      dataIndex: "Id",
    },
    {
      title: "Status",
      render: (_, record) => {
        return <CustomSwitch item={record} onClick={handleUpdateReportStatus} />
      },
    },
    {
      title: "DataHora Geração",
      dataIndex: "DtGeracao_",
    },
    {
      title: "Tipo",
      dataIndex: "RelatorioTipo",
    },
    {
      title: "Mês Ref",
      render: (_, record) => {
        return format(parseISO(record.MesReferencia), "MMMM", { locale: ptBR })
      },
    },
    {
      title: "Período",
      render: (_, record) => {
        return `${record.DataInicio} - ${record.DataCorte}`
      },
    },
    {
      title: "Geração",
      dataIndex: "Status",
    },
    {
      title: "Baixar Base Gerada",
      dataIndex: "Arquivo",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            size={"middle"}
            onClick={() => onClickDownload && onClickDownload(record)}
            loading={downloading === record.Id}
          >
            Download
          </Button>
        )
      },
    },
  ]

  return (
    <Table
      columns={extractDataColumns}
      dataSource={data}
      style={{ width: "100%" }}
      pagination={pagination || false}
      loading={loading}
    />
  )
}

export default ConsolidatedTable
