import React, { memo, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { LogoutOutlined } from "@ant-design/icons"
import { Layout, theme, Breadcrumb, Button, Tooltip, notification } from "antd"
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useNavigate } from "react-router-dom"
import { Sidenav } from "../../components"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { logout } from "../../store/auth/auth.slice"
import { clearMessage } from "../../store/message/message.slice"
import { EventsGatway } from "../../components/Events"

const { Header, Content } = Layout

function AppLayout({
  children,
  selectedKeys = ["0"],
  title,
}: {
  children?: React.ReactNode
  selectedKeys: Array<string>
  title?: Array<string>
}) {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { message, type } = useAppSelector((state) => state.message)
  const { isLoggedIn } = useAppSelector((state) => state.auth)

  const [api, contextHolder] = notification.useNotification()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  useEffect(() => {
    if (message) {
      api.open({
        message: message,
        type: type,
        duration: 5000,
        onClose() {
          dispatch(clearMessage())
        },
      })
    }
  }, [message])

  useEffect(() => {
    if (!isLoggedIn) navigate("/", { replace: true })
  }, [isLoggedIn])

  const handleLogout = () => {
    dispatch(logout())
    navigate("/", { replace: true })
  }

  return (
    <>
      <Layout
        style={{
          height: "100%",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>VIPZZ Admin ETL</title>
          <link
            href="https://fonts.cdnfonts.com/css/helvetica-neue-55"
            rel="stylesheet"
          />
        </Helmet>
        <Sidenav
          selectedKeys={selectedKeys}
          collapsed={collapsed}
          setCollapsed={(value) => setCollapsed(value)}
        />
        <Layout
          style={{
            height: "100%",
            backgroundColor: "#c9c9c9",
          }}
        >
          <Header
            style={{
              padding: "0 24px",
              background: colorBgContainer,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Tooltip placement="bottom" title="Expande o menu">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </Tooltip>
            <Breadcrumb style={{ margin: "16px 0", flex: 1 }}>
              {title?.map((item, index) => (
                <Breadcrumb.Item key={index.toString()}>{item}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <Tooltip title="Logout">
              <Button
                type="text"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              />
            </Tooltip>
          </Header>
          <PerfectScrollbar>
            <Content style={{ margin: "24px 16px" }}>{children}</Content>
          </PerfectScrollbar>
        </Layout>
        {contextHolder}
        <EventsGatway />
      </Layout>
    </>
  )
}

export default memo(AppLayout)
