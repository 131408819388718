import { createBrowserRouter } from "react-router-dom"
import { Home, ErrorPage, Consolidated, SignIn, InsertBase } from "./pages"
import { Accelerators, Award, Card, KPI } from "./pages/totalizers"
import BaseGoal from "./pages/base-goal/base-goal"
import BaseVolumePositiveMonthly from "./pages/base-volume-positive-monthly/base-volume-positive-monthly"
import BaseVolumePositiveTotal from "./pages/base-volume-positive-total/base-volume-positive-total"
import LinkSellerSupervisor from "./pages/link-seller-supervisor/link-seller-supervisor"
import LinkSellerCustomer from "./pages/link-seller-customer/link-seller-customer"
// import SellersMonth from "./pages/ranking/sellers-month/sellers-month"
// import SellersTrim from "./pages/ranking/sellers-trim/sellers-trim"
// import SupervisorTrim from "./pages/ranking/supervisor-trim/supervisor-trim"
// import SupervisorMonth from "./pages/ranking/supervisor-month/supervisor-month"
// import { ManagersRanking } from "./pages/ranking"

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: <SignIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/importar-bases",
    element: <InsertBase />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/consolidado",
    element: <Consolidated />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/base-meta-pontuacao",
    element: <BaseGoal />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/base-volume-positivacao-mensal",
    element: <BaseVolumePositiveMonthly />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/base-volume-positivacao-total",
    element: <BaseVolumePositiveTotal />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/vinculo-cliente-vendedor",
    element: <LinkSellerCustomer />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exportar/vinculo-vendedor-supervisor",
    element: <LinkSellerSupervisor />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/exportar/ranking-vendedores-mes",
  //   element: <SellersMonth />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/exportar/ranking-vendedores-trimestre",
  //   element: <SellersTrim />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/exportar/ranking-supervisores-trimestre",
  //   element: <SupervisorTrim />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/exportar/ranking-supervisores-mes",
  //   element: <SupervisorMonth />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/exportar/ranking-gerentes-coordenadores",
  //   element: <ManagersRanking />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/totalizadores/card",
    element: <Card />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/totalizadores/premiacoes",
    element: <Award />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/totalizadores/kpi",
    element: <KPI />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/totalizadores/aceleradores",
    element: <Accelerators />,
    errorElement: <ErrorPage />,
  },
])

export default router
