import { memo, useMemo } from "react"
import { Select, Table } from "antd"
import { FaFilter } from "react-icons/fa"
import { AppLayout } from "../.."
import * as S from "./kpi.module"
import { kpiUsecase } from "./kpi.usecase"
import type { ColumnType, ColumnsType } from "antd/es/table"
import { IKPIPositivationTargetByRegionalDataType } from "./interfaces"
// import { ConsolidatedTable } from "../../../components/table"
import {
  IAveragePrice,
  IPositiveClients,
  IPositiveClientsTargetMonthly,
  IPositiveClientsTargetRanking,
} from "../../../store/totalizer/interfaces"

function KPI() {
  const {
    loading,
    data,
    handleFilter,
    totalizerLoading,
    kpiRegional,
    // dataExtraction,
    handleChange,
    positiveClients,
    positiveClientsTargetMonthly,
    positiveClientsTargetRanking,
    averagePrice,
    averagePriceTotal,
  } = kpiUsecase()

  const columns: ColumnsType<IKPIPositivationTargetByRegionalDataType> = [
    {
      title: "Regional",
      dataIndex: "Regional",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          total += kpiRegional[i].Positivacao
        }

        total = Math.round(total)
        const formattedTotal = total.toLocaleString()

        return (
          <S.Col $align="center">
            <S.Text>{formattedTotal}</S.Text>
            <S.Text>PSV</S.Text>
          </S.Col>
        )
      },
      dataIndex: "Positivacao",
      align: "center",
    },
    {
      title: () => {
        let totalAmount = 0
        let PositivacaoCadastradado = 0

        for (let i = 0; i < kpiRegional?.length; i++) {
          totalAmount += kpiRegional[i].Positivacao
          PositivacaoCadastradado += kpiRegional[i].PositivacaoCadastradado
        }

        let total = (PositivacaoCadastradado / totalAmount) * 100

        if (Number.isNaN(total)) total = 0

        return (
          <S.Col $align="center">
            <S.Text>{total.toFixed(2)}%</S.Text>
            <S.Text>% CAD</S.Text>
          </S.Col>
        )
      },
      render: (_, record) => {
        let total = (record.PositivacaoCadastradado / record.Positivacao) * 100

        if (Number.isNaN(total)) {
          total = 0
        }

        return `${total.toFixed(2)}%`
      },
      align: "center",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          total += kpiRegional[i].MetaAtingida
        }

        total = Math.round(total)
        const formattedTotal = total.toLocaleString()

        return (
          <S.Col $align="center">
            <S.Text>{formattedTotal}</S.Text>
            <S.Text>ATINGIRAM META</S.Text>
          </S.Col>
        )
      },
      dataIndex: "MetaAtingida",
      align: "center",
    },
    {
      title: () => {
        let totalTarget = 0
        let regionaltarget = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          totalTarget += kpiRegional[i].MetaAtingida
          regionaltarget += kpiRegional[i].TotalMesesMetaAtingidaCadastrado
        }
        let total = (regionaltarget / totalTarget) * 100
        if (Number.isNaN(total)) {
          total = 0
        }
        return (
          <S.Col $align="center">
            <S.Text>{total.toFixed(2)}</S.Text>
            <S.Text>% CAD</S.Text>
          </S.Col>
        )
      },
      render: (_, record) => {
        let totalTarget = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          totalTarget += kpiRegional[i].MetaAtingida
        }
        let total =
          (record.TotalMesesMetaAtingidaCadastrado / totalTarget) * 100
        if (Number.isNaN(total)) {
          total = 0
        }
        return `${total.toFixed(2)}%`
      },
      align: "center",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          total += kpiRegional[i].VolumeRealizado
        }

        total = Math.round(total)
        const formattedTotal = total.toLocaleString()

        return (
          <S.Col $align="center">
            <S.Text>{formattedTotal}</S.Text>
            <S.Text>VOL REAL</S.Text>
          </S.Col>
        )
      },
      align: "center",
      render: (_, record) => {
        return `${record.VolumeRealizado.toFixed(2)}`
      },
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < kpiRegional?.length; i++) {
          total += kpiRegional[i].RobRealizado
        }

        total = Math.round(total)
        const formattedTotal = total.toLocaleString()

        return (
          <S.Col $align="center">
            <S.Text>{formattedTotal}</S.Text>
            <S.Text>ROB REAL</S.Text>
          </S.Col>
        )
      },
      align: "center",
      render: (_, record) => {
        return `${record.RobRealizado.toFixed(2)}`
      },
    },
  ]

  const totalClientsPositivation: ColumnsType<IPositiveClients> = [
    {
      title: "Freq.",
      render: (_, record) => {
        if (record.QtdeMeses < 1) return "-"
        if (record.QtdeMeses === 1) return `${record.QtdeMeses} mês`
        if (record.QtdeMeses > 1) return `${record.QtdeMeses} meses`
        return
      },
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < positiveClients?.length; i++) {
          total += positiveClients[i].TotalClientes
        }

        total = Math.round(total)
        const formattedTotal = total.toLocaleString()

        return (
          <S.Col $align="center">
            <S.Text>{formattedTotal}</S.Text>
            <S.Text>Total Clientes</S.Text>
          </S.Col>
        )
      },
      dataIndex: "TotalClientes",
      align: "center",
    },
    {
      title: "% Total",
      dataIndex: "PercTotal",
    },
  ]

  const totalClientsPositivationTargetMonthly: ColumnsType<IPositiveClientsTargetMonthly> =
    [
      {
        title: "Freq.",
        render: (_, record) => {
          if (record.QtdeMeses < 1) return "-"
          if (record.QtdeMeses === 1) return `${record.QtdeMeses} mês`
          if (record.QtdeMeses > 1) return `${record.QtdeMeses} meses`
          return
        },
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < positiveClientsTargetMonthly?.length; i++) {
            total += positiveClientsTargetMonthly[i].TotalClientes
          }

          return (
            <S.Col $align="center">
              <S.Text>{total}</S.Text>
              <S.Text>Total Clientes</S.Text>
            </S.Col>
          )
        },
        dataIndex: "TotalClientes",
        align: "center",
      },
      {
        title: "% Total",
        dataIndex: "PercTotal",
      },
    ]

  const totalClientsPositivationTargetRanking: ColumnsType<IPositiveClientsTargetRanking> =
    [
      {
        title: "Class.",
        dataIndex: "Classificacao",
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < positiveClientsTargetRanking?.length; i++) {
            total += positiveClientsTargetRanking[i].TotalClientes
          }

          return (
            <S.Col $align="center">
              <S.Text>{total}</S.Text>
              <S.Text>Total Clientes</S.Text>
            </S.Col>
          )
        },
        dataIndex: "TotalClientes",
        align: "center",
      },
      {
        title: "% Total",
        dataIndex: "PercTotal",
      },
    ]

  const renderAveragePriceColumns = useMemo(() => {
    const columns: ColumnsType<IAveragePrice> = [
      {
        title: "Regional",
        dataIndex: "Regional",
      },
    ]

    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ]

    averagePrice?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const hasIndex = columns.find(
          (column: ColumnType<IAveragePrice>) => column.dataIndex === key,
        )
        if (months.includes(key) && !hasIndex) {
          // Usar o total do mês da estrutura averagePriceTotal
          const totalForMonth = averagePriceTotal[0][key] || 0

          columns.push({
            dataIndex: key,
            title: () => {
              return (
                <S.Col $align="center">
                  <S.Text>{totalForMonth.toFixed(2)}</S.Text>
                  <S.Text>{key}</S.Text>
                </S.Col>
              )
            },
            align: "center",
          })
        }
      })
    })

    return columns
  }, [averagePrice, averagePriceTotal])

  return (
    <AppLayout selectedKeys={["2", "2.2"]} title={["Totalizadores", "KPIs"]}>
      <S.Wrapper $marginBottom="30px">
        <Select
          onChange={handleChange}
          options={data}
          style={{ width: 200 }}
          placeholder="Selecione um período"
        />
        <S.CustomButton
          type="primary"
          icon={<FaFilter />}
          disabled={!data || loading}
          onClick={handleFilter}
        >
          Filtrar
        </S.CustomButton>
        {/* <S.CustomButton type="primary" icon={<FaCog />}>
          Gerar nova base consolidada
        </S.CustomButton> */}
      </S.Wrapper>
      <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <S.Col $gap="15px">
          <Table
            bordered
            caption={
              <S.Col
                style={{
                  alignItems: "flex-start",
                  backgroundColor: "var(--color-primary)",
                  padding: "0 10px",
                }}
              >
                <S.Title style={{ color: "#fff" }}>
                  Total Positivação e Atingimento Meta
                </S.Title>
                <S.Text style={{ color: "#fff" }}>
                  Consolidado por REGIONAL
                </S.Text>
              </S.Col>
            }
            columns={columns}
            dataSource={kpiRegional}
            loading={totalizerLoading}
            style={{ flex: 1, minWidth: 250 }}
            pagination={false}
          />
          <S.Row>
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>
                    Total Clientes Positivaram
                  </S.Title>
                  <S.Text style={{ color: "#fff" }}>
                    Consolidado por Frequência Mensal
                  </S.Text>
                </S.Col>
              }
              columns={totalClientsPositivation}
              dataSource={positiveClients}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>
                    Total Clientes Positivaram Bateram Meta
                  </S.Title>
                  <S.Text style={{ color: "#fff" }}>
                    Consolidado por Frequência Mensal
                  </S.Text>
                </S.Col>
              }
              columns={totalClientsPositivationTargetMonthly}
              dataSource={positiveClientsTargetMonthly}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>
                    Total Clientes Positivaram Bateram Meta
                  </S.Title>
                  <S.Text style={{ color: "#fff" }}>
                    Consolidado por Classificação
                  </S.Text>
                </S.Col>
              }
              columns={totalClientsPositivationTargetRanking}
              dataSource={positiveClientsTargetRanking}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
            <Table
              bordered
              caption={
                <S.Col
                  style={{
                    alignItems: "flex-start",
                    backgroundColor: "var(--color-primary)",
                    padding: "0 10px",
                  }}
                >
                  <S.Title style={{ color: "#fff" }}>Preço Médio</S.Title>
                  <S.Text style={{ color: "#fff" }}>
                    Preço Médio por Regional
                  </S.Text>
                </S.Col>
              }
              columns={renderAveragePriceColumns}
              dataSource={averagePrice}
              style={{ flex: 1, minWidth: 250 }}
              pagination={false}
            />
          </S.Row>
        </S.Col>
      </S.Wrapper>
      {/* <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <ConsolidatedTable data={dataExtraction} loading={loading} />
      </S.Wrapper> */}
    </AppLayout>
  )
}

export default memo(KPI)
