import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit"
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"

import home from "./home/home.slice"
import auth from './auth/auth.slice'
import message from './message/message.slice'
import totalizer from './totalizer/totatilzer.slice'
import imports from './import/import.slice'

const persitConfig = {
  key: 'roor',
  storage,
}

export const rootReducer = combineReducers({
  home,
  auth,
  message,
  totalizer,
  imports
})

const persistedReducer = persistReducer(persitConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export { store, persistor }
