import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  getAcceleratorNewSignUp,
  getAcceleratorPositiveClients,
  getAcceleratorTargetClients,
  getAcceleratorTotalAccelerator,
  getAcceleratorTotalProgram,
  getRelatorioHistorico,
} from "../../../store/totalizer/model"
// import { getReports } from "../../../store/home/model"
import { clearTotalizers } from "../../../store/totalizer/totatilzer.slice"
import { dateFormatted } from "../../../utils/date.utils"

function acceleratorsUsecase() {
  const dispatch = useAppDispatch()
  const { error, loading, reports } = useAppSelector((state) => state.home)
  const { data: authData } = useAppSelector((state) => state.auth)
  const {
    loading: totalizerLoading,
    dataExtraction,
    acceleratorTotalByAccelerator,
    acceleratorNewSignUp,
    acceleratorClientsTarget,
    acceleratorPositiveClients,
    acceleratorTotalProgram,
    dataRelatorioHistorico,
  } = useAppSelector((state) => state.totalizer)

  const [IdRelatorioHistorico, setIdRelatorioHistorico] = useState<number>(0)

  useEffect(() => {
    dispatch(clearTotalizers())
  }, [])

  const handleChange = useCallback((value: number) => {
    setIdRelatorioHistorico(value)
  }, [])

  const handleFilter = useCallback(() => {
    dispatch(
      getAcceleratorTargetClients({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getAcceleratorPositiveClients({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getAcceleratorNewSignUp({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getAcceleratorTotalAccelerator({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getAcceleratorTotalProgram({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
  }, [dispatch, IdRelatorioHistorico, authData?.userId])

  const totalByAcceleratorMap = useMemo(() => {
    const map: {
      name: string
      metaAtingida: number
      metaNaoAtingida: number
    }[] = []
    acceleratorTotalByAccelerator?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key.indexOf("ProdutoEstrelaMetaAtingida") !== -1)
          map.push({
            name: "PRODUTO ESTRELA",
            metaAtingida: item.ProdutoEstrelaMetaAtingida,
            metaNaoAtingida: item.ProdutoEstrelaMetaNaoAtingida,
          })
        if (key.indexOf("TotalClientesQtdeItens") !== -1)
          map.push({
            name: "MIX PROD. A PARTIR DE 5",
            metaAtingida: item.TotalClientesQtdeItens,
            metaNaoAtingida: item.QtdeItensMetaNaoAtingida,
          })
        if (key.indexOf("FrequenciaMetaAtingida") !== -1)
          map.push({
            name: "FREQ. MAIOR 3 MESES",
            metaAtingida: item.FrequenciaMetaAtingida,
            metaNaoAtingida: item.FrequenciaMetaNaoAtingida,
          })
      })
    })

    return map
  }, [acceleratorTotalByAccelerator])

  const handleCardConsolidatedReport = useCallback(() => {
    // esse valor ainda precisa ser alterado para o novo tipo de relatorio
    //dispatch(getReports({ relatorioBaseTipo: 7 }))
    dispatch(getRelatorioHistorico({}))
  }, [dispatch])

  useEffect(() => {
    const bootstrap = () => {
      handleCardConsolidatedReport()
    }

    return () => {
      bootstrap()
    }
  }, [dispatch])

  const data = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.Id} ${item.NomeRelatorioTipo} (${dateFormatted(
        item.DataInicio,
        "dd/MM/yyyy",
      )} a ${dateFormatted(item.DataCorte, "dd/MM/yyyy")})`,
    }))
  }, [dataRelatorioHistorico])

  return {
    handleChange,
    error,
    loading,
    reports,
    data,
    totalizerLoading,
    handleFilter,
    dataExtraction,
    acceleratorTotalByAccelerator,
    acceleratorNewSignUp,
    acceleratorClientsTarget,
    acceleratorPositiveClients,
    acceleratorTotalProgram,
    totalByAcceleratorMap,
  }
}

export { acceleratorsUsecase }
