import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  getKPIAveragePrice,
  getKPIAveragePriceTotal,
  getKPIPositivationAndTarget,
  getKPIPositivationClient,
  getKPIPositivationClientTarget,
  getKPIPositivationClientTargetClassification,
  getRelatorioHistorico,
} from "../../../store/totalizer/model"
//import { getReports } from "../../../store/home/model"
import { dateFormatted } from "../../../utils/date.utils"

function kpiUsecase() {
  const dispatch = useAppDispatch()
  const { error, loading, reports } = useAppSelector((state) => state.home)
  const {
    regional,
    kpiRegional,
    loading: totalizerLoading,
    dataExtraction,
    positiveClients,
    positiveClientsTargetMonthly,
    positiveClientsTargetRanking,
    averagePrice,
    averagePriceTotal,
    dataRelatorioHistorico,
  } = useAppSelector((state) => state.totalizer)

  const { data: authData } = useAppSelector((state) => state.auth)

  const [IdRelatorioHistorico, setIdRelatorioHistorico] = useState<number>(0)

  const handleChange = useCallback((value: number) => {
    setIdRelatorioHistorico(value)
  }, [])

  const handleFilter = useCallback(() => {
    dispatch(
      getKPIPositivationAndTarget({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getKPIPositivationClient({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getKPIPositivationClientTarget({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getKPIPositivationClientTargetClassification({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getKPIAveragePrice({ IdRelatorioHistorico, userId: authData?.userId }),
    )
    dispatch(
      getKPIAveragePriceTotal({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
  }, [dispatch, IdRelatorioHistorico, authData?.userId])

  const handleCardConsolidatedReport = useCallback(() => {
    // esse valor ainda precisa ser alterado para o novo tipo de relatorio
    //dispatch(getReports({ relatorioBaseTipo: 7 }))
    dispatch(getRelatorioHistorico({}))
  }, [dispatch])

  useEffect(() => {
    const bootstrap = () => {
      handleCardConsolidatedReport()
    }

    return () => {
      bootstrap()
    }
  }, [dispatch])

  const data = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.Id} ${item.NomeRelatorioTipo} (${dateFormatted(
        item.DataInicio,
        "dd/MM/yyyy",
      )} a ${dateFormatted(item.DataCorte, "dd/MM/yyyy")})`,
    }))
  }, [dataRelatorioHistorico])

  return {
    handleChange,
    error,
    loading,
    reports,
    data,
    regional,
    totalizerLoading,
    handleFilter,
    dataExtraction,
    kpiRegional,
    positiveClients,
    positiveClientsTargetMonthly,
    positiveClientsTargetRanking,
    averagePrice,
    averagePriceTotal,
  }
}

export { kpiUsecase }
