import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { authenticate } from "./model"
import { IAuthResponse, IAuth } from "./interfaces"
import { clearSessionToken, setSessionToken } from "../../utils/sesion.utils"

export interface IAuthInitialState {
  data: IAuth | null
  loading: boolean
  isLoggedIn: boolean
}

const initialState: IAuthInitialState = {
  data: null,
  loading: false,
  isLoggedIn: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      clearSessionToken()
      state.data = null
      state.loading = false
      state.isLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.loading = true
      })
      .addCase(
        authenticate.fulfilled,
        (state, { payload }: PayloadAction<IAuthResponse>) => {
          setSessionToken(payload.data.token)
          state.loading = false
          state.data = payload.data
          state.isLoggedIn = true
        },
      )
      .addCase(authenticate.rejected, (state) => {
        state.loading = false
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
