import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getReportFilterData, getReports } from "./model"
import { IFilterData, IReport, IReportPeriod, IReportResponse, IResponseFilterData } from "./interfaces"

export interface IHomeInitialState {
  reports: IReport[]
  data: IReportPeriod[]
  loading: boolean
  error: string
}

const initialState: IHomeInitialState = {
  reports: [],
  data: [],
  loading: false,
  error: ""
}

function reportOptions(data: IFilterData[]): IReportPeriod[] {
  return data?.map((report: IFilterData) => ({
    value: report.IdRelatorioHistorico,
    label: `${report.IdRelatorioHistorico} - ${report.TextForm}`,
  }))
}

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state) => {
        state.loading = true
      })
      .addCase(getReports.fulfilled, (state, { payload }: PayloadAction<IReportResponse>) => {
        state.loading = false
        state.reports = payload.data
      })
      .addCase(getReports.rejected, (state) => {
        state.loading = false
      })
      .addCase(getReportFilterData.pending, (state) => {
        state.loading = true
      })
      .addCase(getReportFilterData.fulfilled, (state, { payload }: PayloadAction<IResponseFilterData>) => {
        state.loading = false
        state.data = reportOptions(payload.data)
      })
      .addCase(getReportFilterData.rejected, (state) => {
        state.loading = false
      })
  },
})


export default homeSlice.reducer
