import { memo } from "react"
import { Select, Table } from "antd"
import { FaFilter } from "react-icons/fa"
import { AppLayout } from "../.."
import * as S from "./cards.module"
import { cardUsecase } from "./cards.usecase"
import type { ColumnsType } from "antd/es/table"
import { ICardDataType } from "./interfaces"
// import { ConsolidatedTable } from "../../../components/table"

function Cards() {
  const {
    handleChange,
    loading,
    data,
    handleFilter,
    // handleCardConsolidatedReport,
    card,
    totalizerLoading,
    // dataExtraction,
    // downloadReport,
  } = cardUsecase()

  const columns: ColumnsType<ICardDataType> = [
    {
      title: "Regional",
      dataIndex: "Regional",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < card?.length; i++) {
          total += card[i].Positivacao
        }
        return (
          <S.Col>
            <S.Text>Positivados</S.Text>
            <S.Text>{total}</S.Text>
          </S.Col>
        )
      },
      dataIndex: "Positivacao",
      align: "center",
    },
    {
      title: () => {
        let totalAmount = 0
        let totalMeta = 0
        for (let i = 0; i < card?.length; i++) {
          totalAmount += card[i].Positivacao
          totalMeta += card[i].MetaAtingida
        }

        let total = (totalMeta / totalAmount) * 100

        if (Number.isNaN(total)) total = 0

        return (
          <S.Col>
            <S.Text>Atingiram meta</S.Text>
            <S.Text>{total.toFixed(2)}%</S.Text>
          </S.Col>
        )
      },
      render: (_, record) => {
        let total = (record.MetaAtingida / record.Positivacao) * 100

        if (Number.isNaN(total)) {
          total = 0
        }

        return `${total.toFixed(2)}%`
      },
      align: "center",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < card?.length; i++) {
          total += card[i].CadastroSiteEfetuado
        }
        return (
          <S.Col>
            <S.Text>Cadastros</S.Text>
            <S.Text>{total}</S.Text>
          </S.Col>
        )
      },
      dataIndex: "CadastroSiteEfetuado",
      align: "center",
    },
    {
      title: () => {
        let total = 0
        for (let i = 0; i < card?.length; i++) {
          total += card[i].ResgatesRealizados
        }
        return (
          <S.Col>
            <S.Text>Resgates Realizados</S.Text>
            <S.Text>{total}</S.Text>
          </S.Col>
        )
      },
      dataIndex: "ResgatesRealizados",
      align: "center",
    },
  ]

  return (
    <AppLayout selectedKeys={["2", "2.1"]} title={["Totalizadores", "Card"]}>
      <S.Wrapper $marginBottom="30px">
        <Select
          onChange={handleChange}
          options={data}
          style={{ width: 300 }}
          placeholder="Selecione um período"
        />
        <S.CustomButton
          type="primary"
          icon={<FaFilter />}
          disabled={!data || loading}
          onClick={handleFilter}
        >
          Filtrar
        </S.CustomButton>
        {/* <S.CustomButton
          type="primary"
          icon={<FaCog />}
          onClick={() => handleCardConsolidatedReport("_card.xlsx")}
        >
          Gerar nova base consolidada
        </S.CustomButton> */}
      </S.Wrapper>
      <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <Table
          columns={columns}
          dataSource={card}
          style={{ width: "100%" }}
          pagination={false}
          loading={totalizerLoading}
        />
      </S.Wrapper>
      {/* <S.Wrapper $alignItems="center" $justifyContent="center">
        <ConsolidatedTable
          data={dataExtraction}
          loading={loading}
          onClickDownload={downloadReport}
        />
      </S.Wrapper> */}
    </AppLayout>
  )
}

export default memo(Cards)
