import Cookies from 'js-cookie'

export const setSessionToken = (token: string) => {
  Cookies.set('session_token', token, { expires: 1 })
}

export const getSessionToken = () => {
  return Cookies.get('session_token');
};


export const clearSessionToken = () => {
  Cookies.remove('session_token');
};
