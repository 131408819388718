import { useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { getReportFilterData } from "../../store/home/model";

function homeUsecase() {
  const dispatch = useAppDispatch();

  const { error, loading, reports, data } = useAppSelector(
    (state) => state.home,
  )

  const handleConsolidatedReport = useCallback(() => {
    dispatch(getReportFilterData());
  }, [dispatch])

  useEffect(() => {
    const bootstrap = () => {
      handleConsolidatedReport()
    }

    return () => {
      bootstrap()
    }
  }, [])

  const handleChange = useCallback((value: string) => {
    console.log(value)
  }, [])

  return {
    handleChange,
    error,
    loading,
    reports,
    data,
  }
}

export { homeUsecase }
