import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  getAcceleratorNewSignUp,
  getAcceleratorPositiveClients,
  getAcceleratorTargetClients,
  getAcceleratorTotalAccelerator,
  getAcceleratorTotalProgram,
  getAwardDistributedPrizes,
  getAwardPositivationAndTarget,
  getCardTotalizerData,
  getDataExtract,
  getKPIAveragePrice,
  getKPIAveragePriceTotal,
  getKPIPositivationAndTarget,
  getKPIPositivationClient,
  getKPIPositivationClientTarget,
  getKPIPositivationClientTargetClassification,
  getRelatorioHistorico,
} from "./model"
import {
  IDataExtraction,
  ICardTotal,
  IResponseCardTotal,
  IExtractDataResponse,
  IKPIPositivationTargetByRegional,
  IResponseKPIPositivationTargetByRegional,
  IPositiveClients,
  IPositiveClientsTargetMonthly,
  IPositiveClientsTargetRanking,
  IAveragePrice,
  IAveragePriceTotal,
  IResponsePositiveClientsTargetRanking,
  IResponsePositiveClientsTargetMonthly,
  IResponsePositiveClients,
  ITotalByAccelerator,
  IResponseTotalByAccelerator,
  IResponseAcceleratorNewSignUps,
  IAcceleratorPositiveClients,
  IAcceleratorClientsTarget,
  IAcceleratorTotalProgram,
  IAcceleratorNewSignUps,
  IResponseAcceleratorTargetClients,
  IResponseAcceleratorPositiveClients,
  IResponseAcceleratorTotalProgram,
  IResponseAwardPositivationAndTarget,
  IResponseAwardDistributedPrizes,
  IAwardPositivationAndTarget,
  IAwardDistributedPrizes,
  IResponseIAveragePrice,
  IResponseIAveragePriceTotal,
  IRelatorioHistoricoResponse,
  IRelatorioHistorico,
} from "./interfaces"
import { IRegionalTotal } from "../../components/table/interfaces"

export interface ITotalizerInitialState {
  card: ICardTotal[]
  dataExtraction: IDataExtraction[]
  dataRelatorioHistorico: IRelatorioHistorico[]
  regional: IRegionalTotal[]
  kpiRegional: IKPIPositivationTargetByRegional[]
  positiveClients: IPositiveClients[]
  positiveClientsTargetMonthly: IPositiveClientsTargetMonthly[]
  positiveClientsTargetRanking: IPositiveClientsTargetRanking[]
  averagePrice: IAveragePrice[]
  averagePriceTotal: IAveragePriceTotal[]
  acceleratorTotalByAccelerator: ITotalByAccelerator[]
  acceleratorNewSignUp: IAcceleratorNewSignUps[]
  acceleratorPositiveClients: IAcceleratorPositiveClients[]
  acceleratorClientsTarget: IAcceleratorClientsTarget[]
  acceleratorTotalProgram: IAcceleratorTotalProgram[]
  awardPositivationAndtarget: IAwardPositivationAndTarget[]
  awardDistributedPrizes: IAwardDistributedPrizes[]
  loading: boolean
}

const initialState: ITotalizerInitialState = {
  card: [],
  regional: [],
  kpiRegional: [],
  dataExtraction: [],
  loading: false,
  positiveClients: [],
  positiveClientsTargetMonthly: [],
  positiveClientsTargetRanking: [],
  averagePrice: [],
  averagePriceTotal: [],
  acceleratorTotalByAccelerator: [],
  acceleratorNewSignUp: [],
  acceleratorClientsTarget: [],
  acceleratorPositiveClients: [],
  acceleratorTotalProgram: [],
  awardPositivationAndtarget: [],
  awardDistributedPrizes: [],
  dataRelatorioHistorico: [],
}

export const totalizerSlice = createSlice({
  name: "totalizer",
  initialState,
  reducers: {
    clearTotalizers: (state) => {
      state.card = []
      state.regional = []
      state.kpiRegional = []
      state.dataExtraction = []
      state.loading = false
      state.positiveClients = []
      state.positiveClientsTargetMonthly = []
      state.positiveClientsTargetRanking = []
      state.averagePrice = []
      state.averagePriceTotal = []
      state.acceleratorTotalByAccelerator = []
      state.acceleratorNewSignUp = []
      state.acceleratorClientsTarget = []
      state.acceleratorPositiveClients = []
      state.acceleratorTotalProgram = []
    },
    updateStatus: (
      state,
      { payload }: PayloadAction<{ id: number; Ativo: number }>,
    ) => {
      state.dataExtraction?.forEach((item) => {
        if (item.Id === payload.id) item.Ativo = payload.Ativo
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardTotalizerData.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getCardTotalizerData.fulfilled,
        (state, { payload }: PayloadAction<IResponseCardTotal>) => {
          state.loading = false
          state.card = payload.data
        },
      )
      .addCase(getCardTotalizerData.rejected, (state) => {
        state.loading = false
      })
      .addCase(getRelatorioHistorico.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getRelatorioHistorico.fulfilled,
        (state, { payload }: PayloadAction<IRelatorioHistoricoResponse>) => {
          state.loading = false
          state.dataRelatorioHistorico = payload.data.sort((a, b) => {
            if (a.Id && b.Id) return a.Id - b.Id - (b.Id - a.Id)

            return 0
          })
        },
      )
      .addCase(getRelatorioHistorico.rejected, (state) => {
        state.loading = false
      })
      .addCase(getDataExtract.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getDataExtract.fulfilled,
        (state, { payload }: PayloadAction<IExtractDataResponse>) => {
          state.loading = false
          state.dataExtraction = payload.data
        },
      )
      .addCase(getDataExtract.rejected, (state) => {
        state.loading = false
      })
      /** KPI */
      .addCase(getKPIAveragePrice.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getKPIAveragePrice.fulfilled,
        (state, { payload }: PayloadAction<IResponseIAveragePrice>) => {
          state.loading = false
          state.averagePrice = payload.data
        },
      )
      .addCase(getKPIAveragePrice.rejected, (state) => {
        state.loading = false
      })
      .addCase(getKPIAveragePriceTotal.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getKPIAveragePriceTotal.fulfilled,
        (state, { payload }: PayloadAction<IResponseIAveragePriceTotal>) => {
          state.loading = false
          state.averagePriceTotal = payload.data
        },
      )
      .addCase(getKPIAveragePriceTotal.rejected, (state) => {
        state.loading = false
      })
      .addCase(getKPIPositivationAndTarget.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getKPIPositivationAndTarget.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseKPIPositivationTargetByRegional>,
        ) => {
          state.loading = false
          state.kpiRegional = payload.data
        },
      )
      .addCase(getKPIPositivationAndTarget.rejected, (state) => {
        state.loading = false
      })
      .addCase(getKPIPositivationClient.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getKPIPositivationClient.fulfilled,
        (state, { payload }: PayloadAction<IResponsePositiveClients>) => {
          state.loading = false
          state.positiveClients = payload.data
        },
      )
      .addCase(getKPIPositivationClient.rejected, (state) => {
        state.loading = false
      })
      .addCase(getKPIPositivationClientTarget.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getKPIPositivationClientTarget.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponsePositiveClientsTargetMonthly>,
        ) => {
          state.loading = false
          state.positiveClientsTargetMonthly = payload.data
        },
      )
      .addCase(getKPIPositivationClientTarget.rejected, (state) => {
        state.loading = false
      })
      .addCase(
        getKPIPositivationClientTargetClassification.pending,
        (state) => {
          state.loading = true
        },
      )
      .addCase(
        getKPIPositivationClientTargetClassification.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponsePositiveClientsTargetRanking>,
        ) => {
          state.loading = false
          state.positiveClientsTargetRanking = payload.data
        },
      )
      .addCase(
        getKPIPositivationClientTargetClassification.rejected,
        (state) => {
          state.loading = false
        },
      )
      /**accelerator */
      .addCase(getAcceleratorTotalProgram.pending, (state) => {
        state.loading = true
      })
      /** accelerator total program */
      .addCase(
        getAcceleratorTotalProgram.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseAcceleratorTotalProgram>,
        ) => {
          state.loading = false
          state.acceleratorTotalProgram = payload.data
        },
      )
      .addCase(getAcceleratorTotalProgram.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAcceleratorTotalAccelerator.pending, (state) => {
        state.loading = true
      })
      /** accelerator total by accelerator */
      .addCase(
        getAcceleratorTotalAccelerator.fulfilled,
        (state, { payload }: PayloadAction<IResponseTotalByAccelerator>) => {
          state.loading = false
          state.acceleratorTotalByAccelerator = payload.data
        },
      )
      .addCase(getAcceleratorTotalAccelerator.rejected, (state) => {
        state.loading = false
      })
      /** accelerator new signup */
      .addCase(getAcceleratorNewSignUp.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAcceleratorNewSignUp.fulfilled,
        (state, { payload }: PayloadAction<IResponseAcceleratorNewSignUps>) => {
          state.loading = false
          state.acceleratorNewSignUp = payload.data
        },
      )
      .addCase(getAcceleratorNewSignUp.rejected, (state) => {
        state.loading = false
      })
      /** accelerator positive client */
      .addCase(getAcceleratorPositiveClients.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAcceleratorPositiveClients.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseAcceleratorPositiveClients>,
        ) => {
          state.loading = false
          state.acceleratorPositiveClients = payload.data
        },
      )
      .addCase(getAcceleratorPositiveClients.rejected, (state) => {
        state.loading = false
      })
      /** accelerator target client */
      .addCase(getAcceleratorTargetClients.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAcceleratorTargetClients.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseAcceleratorTargetClients>,
        ) => {
          state.loading = false
          state.acceleratorClientsTarget = payload.data
        },
      )
      .addCase(getAcceleratorTargetClients.rejected, (state) => {
        state.loading = false
      })
      //* Award */
      /** Positivation And Target */
      .addCase(getAwardPositivationAndTarget.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAwardPositivationAndTarget.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseAwardPositivationAndTarget>,
        ) => {
          state.loading = false
          state.awardPositivationAndtarget = payload.data
        },
      )
      .addCase(getAwardPositivationAndTarget.rejected, (state) => {
        state.loading = false
      })
      /** Distributed Prizes */
      .addCase(getAwardDistributedPrizes.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAwardDistributedPrizes.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseAwardDistributedPrizes>,
        ) => {
          state.loading = false
          state.awardDistributedPrizes = payload.data
        },
      )
      .addCase(getAwardDistributedPrizes.rejected, (state) => {
        state.loading = false
      })
  },
})

export const { clearTotalizers, updateStatus } = totalizerSlice.actions

export default totalizerSlice.reducer
