import styled from "styled-components";
import { Form, Button } from "antd"

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
`

export const LogoContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

export const Logo = styled.img`
  width: 90px;
  height: auto;
`

export const NeonContainerWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px var(--color-secondary); /* Sombra do container */
  border-radius: 8px; /* Borda arredondada */
  overflow: hidden; /* Evita que o efeito de neon vaze além do container */
  width: 100%;
  max-width: 450px;
  gap: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      var(--color-secondary),
      rgba(255, 255, 0, 0)
    );
    z-index: -1;
    filter: blur(30px); /* Valor do blur para o efeito neon */
  }
`;

export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 100%;

  label {
    font-weight: 600;
    color: var(--color-secondary)!important;
  }

  .ant-row,
  .login-form-button {
    width: 100%;
  }
`

export const CustomButton = styled(Button)`
  background-color: var(--color-tertiary)!important;
  text-transform: uppercase;
  font-weight: 600;
`