import { createRef, useCallback } from "react"
import { Button, Typography } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { IReport } from "../../pages/inserts/interfaces"

interface ICustomUpload {
  key?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | undefined) => void
  record: IReport
  disabled?: boolean
}

function CustomUpload({ key, onChange, record, disabled }: ICustomUpload) {
  const inpuFileRef = createRef<HTMLInputElement>()

  const returnFileName = useCallback(() => {
    if (record?.file instanceof File) return record?.file.name
    return
  }, [record?.file])

  return (
    <>
      <input
        ref={inpuFileRef}
        hidden
        aria-hidden={true}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(event) => onChange && onChange(event)}
        id={key}
        style={{ display: "none" }}
      />
      <Button
        icon={<UploadOutlined />}
        onClick={() => {
          inpuFileRef?.current?.click()
        }}
        disabled={disabled}
        aria-disabled={disabled}
      >
        Upload
      </Button>
      {record?.file && <Typography>{returnFileName()}</Typography>}
    </>
  )
}

export default CustomUpload
