import { createGlobalStyle} from 'styled-components'
import { Colors } from '../theme/colors'

export const GlobalStyle = createGlobalStyle`

  :root {
    --color-primary: ${Colors.primary};
    --color-secondary: ${Colors.secondary};
    --color-tertiary: ${Colors.tertiary};
    --color-golden: ${Colors.golden};
    --color-success: ${Colors.success};
    --color-warning: ${Colors.warning};
    --color-danger: ${Colors.danger};
    --color-gray: ${Colors.gray};
    --color-white: ${Colors.white};
    --color-background: ${Colors.gray};
    --color-blue: ${Colors.blue};
    --color-blue-dark: ${Colors.blueDark};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background-color: var(--color-background);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #root {
    height: 100%;
    width: 100%;
  }
`
