
export const Colors = {
  primary: "#000000",
  secondary: "#f6ea43",
  tertiary: "#9c6a26",
  golden: "rgb(250, 208, 76)",
  success: "#000000",
  warning: "#000000",
  danger: "#000000",
  gray: "#f5f5f5",
  darkGray: "#c9c9c9",
  white: "#ffffff",
  blue: "#337ab7",
  blueDark: "#2e6da4",
}
