import { useCallback, useState } from "react"
import { IExtractDataReport } from "./interfaces"
import api from "../../services/base.service"
import { useAppDispatch } from "../../store/hooks"
import { updateStatus } from "../../store/totalizer/totatilzer.slice"

export function useConsolidated() {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const handleUpdateStatus = useCallback((id: number, Ativo: number) => {
    dispatch(updateStatus({ id, Ativo}))
  }, [dispatch])

  const handleUpdateReportStatus = useCallback(
    async (item: IExtractDataReport) => {
      let Ativo = 0

      if (item.Ativo === 1) Ativo = 0
      if (item.Ativo === 0) Ativo = 1

      try {
        setLoading(true)
        const response = await api.patch(`report/generated-report/${item.Id}`, {
          Ativo,
        })
        if (response.data) handleUpdateStatus(item.Id, Ativo)
      } catch (error) {
        console.log(error)
      }
    },
    [handleUpdateStatus],
  )


  return { handleUpdateReportStatus, loading }
}
