import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../services/base.service"
import { IImportResponse, IImportRequest } from "./interfaces"

export const importSalesTeam = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/sales-team", async (data) => {
  const response = await api.post<IImportResponse>(
    `/sales-team/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importQlik = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/qlik", async (data) => {
  const response = await api.post<IImportResponse>(
    `/qlik/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importSiteSignup = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/site-signup", async (data) => {
  const response = await api.post<IImportResponse>(
    `/site-signup/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importClientVipizz = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/client-vipizz", async (data) => {
  const response = await api.post<IImportResponse>(
    `/client-vipizz/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importCliven = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/cliven", async (data) => {
  const response = await api.post<IImportResponse>(
    `/cliven/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importSiteSignupDate = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/site-signup-date", async (data) => {
  const response = await api.post<IImportResponse>(
    `/site-signup-date/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importTarget = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/target", async (data) => {
  const response = await api.post<IImportResponse>(
    `/target/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importRescues = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/rescues", async (data) => {
  const response = await api.post<IImportResponse>(
    `/rescues/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})

export const importSalesTeamHierarchy = createAsyncThunk<
  IImportResponse,
  IImportRequest
>("imports/sales-team-hierarchy", async (data) => {
  const response = await api.post<IImportResponse>(
    `/sales-team-hierarchy/upload`,
    data.formData,
    { params: { IdRelatorioHistorico: data.IdRelatorioHistorico } },
  )
  return response.data
})
