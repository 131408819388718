import { memo } from "react"
import { Select, Table } from "antd"
import { FaFilter } from "react-icons/fa"
import { AppLayout } from "../.."
import * as S from "./award.module"
import { awarddUsecase } from "./award.usecase"
import type { ColumnsType } from "antd/es/table"
// import { ConsolidatedTable } from "../../../components/table"
import {
  IAwardDistributedPrizes,
  IAwardPositivationAndTarget,
} from "../../../store/totalizer/interfaces"

function Award() {
  const {
    handleChange,
    loading,
    data,
    // dataExtraction,
    awardPositivationAndtarget,
    awardDistributedPrizes,
    handleFilter,
  } = awarddUsecase()

  const awardPositivationAndtargetColumns: ColumnsType<IAwardPositivationAndTarget> =
    [
      {
        title: "Regional",
        dataIndex: "Regional",
        align: "center",
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < awardPositivationAndtarget?.length; i++) {
            total += awardPositivationAndtarget[i].MetaAtingida
          }

          return (
            <S.Col $align="center">
              <S.Text>{total}</S.Text>
              <S.Text>Atingimento Meta</S.Text>
            </S.Col>
          )
        },
        dataIndex: "MetaAtingida",
        align: "center",
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < awardPositivationAndtarget?.length; i++) {
            total += awardPositivationAndtarget[i].ValorAtingidoMes
          }

          total = Math.round(total)
          const formattedTotal = total.toLocaleString()

          return (
            <S.Col $align="center">
              <S.Text>{formattedTotal}</S.Text>
              <S.Text>Premiação Disponível</S.Text>
            </S.Col>
          )
        },
        dataIndex: "ValorAcumulado",
        align: "center",
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < awardPositivationAndtarget?.length; i++) {
            total += awardPositivationAndtarget[i].MetaAtingidaCadastrado
          }

          return (
            <S.Col $align="center">
              <S.Text>{total}</S.Text>
              <S.Text>Cadastros</S.Text>
            </S.Col>
          )
        },
        dataIndex: "MetaAtingidaCadastrado",
        align: "center",
      },
      {
        title: () => {
          let totalMetaAtingidaCadastrado = 0
          let totalMetaAtingida = 0
          for (let i = 0; i < awardPositivationAndtarget?.length; i++) {
            totalMetaAtingidaCadastrado +=
              awardPositivationAndtarget[i].MetaAtingidaCadastrado
            totalMetaAtingida += awardPositivationAndtarget[i].MetaAtingida
          }

          let total = (totalMetaAtingidaCadastrado / totalMetaAtingida) * 100

          if (Number.isNaN(total)) return 0

          total = Math.round(total)
          const formattedTotal = total.toLocaleString()

          return (
            <S.Col $align="center">
              <S.Text>{formattedTotal}%</S.Text>
              <S.Text>% Clientes Cadastrados</S.Text>
            </S.Col>
          )
        },
        render: (_, record) => {
          const total =
            (record.MetaAtingidaCadastrado / record.MetaAtingida) * 100
          if (Number.isNaN(total)) return 0

          return total.toFixed(2)
        },
        align: "center",
      },
      {
        title: () => {
          let total = 0
          for (let i = 0; i < awardPositivationAndtarget?.length; i++) {
            total += awardPositivationAndtarget[i].ValorResgatado
          }

          total = Math.round(total * 100) / 100
          const formattedTotal = total.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })

          return (
            <S.Col $align="center">
              <S.Text>{formattedTotal}</S.Text>
              <S.Text>Valor Resgates Realizados</S.Text>
            </S.Col>
          )
        },
        render: (_, record) => {
          return record.ValorResgatado.toFixed(2)
        },
        align: "center",
      },
    ]

  const awardDistributedPrizesColumns: ColumnsType<IAwardDistributedPrizes> = [
    {
      title: "Fornecedor",
      dataIndex: "Fornecedor",
      align: "center",
    },
    {
      title: "Total Prêmios",
      dataIndex: "TotalPremios",
      align: "center",
    },
    {
      title: "% Total",
      render: (_, record) => {
        const total = record.TotalPremios / record.TotalGeralPremios
        if (Number.isNaN(total)) return 0
        return total.toFixed(2)
      },
      align: "center",
    },
  ]

  return (
    <AppLayout
      selectedKeys={["2", "2.4"]}
      title={["Totalizadores", "Premiações"]}
    >
      <S.Wrapper $marginBottom="30px">
        <Select
          onChange={handleChange}
          options={data}
          style={{ width: 200 }}
          placeholder="Selecione um período"
        />
        <S.CustomButton
          type="primary"
          icon={<FaFilter />}
          disabled={!data || loading}
          onClick={handleFilter}
        >
          Filtrar
        </S.CustomButton>
        {/* <S.CustomButton type="primary" icon={<FaCog />}>
          Gerar nova base consolidada
        </S.CustomButton> */}
      </S.Wrapper>
      <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <S.Row>
          <Table
            bordered
            caption={
              <S.Col
                style={{
                  alignItems: "flex-start",
                  backgroundColor: "var(--color-primary)",
                  padding: "0 10px",
                }}
              >
                <S.Title style={{ color: "#fff" }}>
                  Total Positivação e Atingimento Meta
                </S.Title>
                <S.Text style={{ color: "#fff" }}>
                  Consolidado por Regional
                </S.Text>
              </S.Col>
            }
            columns={awardPositivationAndtargetColumns}
            dataSource={awardPositivationAndtarget}
            style={{ flex: 1, minWidth: 250, width: "100%" }}
            pagination={false}
          />
          <Table
            bordered
            caption={
              <S.Col
                style={{
                  alignItems: "flex-start",
                  backgroundColor: "var(--color-primary)",
                  padding: "0 10px",
                }}
              >
                <S.Title style={{ color: "#fff" }}>
                  Clientes que Positivaram
                </S.Title>
                <S.Text style={{ color: "#fff" }}>
                  Total Clientes por frequencia de positivação
                </S.Text>
              </S.Col>
            }
            columns={awardDistributedPrizesColumns}
            dataSource={awardDistributedPrizes}
            style={{ flex: 1, minWidth: 250, width: "100%" }}
            pagination={false}
          />
        </S.Row>
      </S.Wrapper>
      {/* <S.Wrapper
        $alignItems="center"
        $justifyContent="center"
        $marginBottom="30px"
      >
        <ConsolidatedTable data={dataExtraction} loading={loading} />
      </S.Wrapper> */}
    </AppLayout>
  )
}

export default memo(Award)
