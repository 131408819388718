import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
//import { getReports } from "../../../store/home/model";
import { clearTotalizers } from "../../../store/totalizer/totatilzer.slice"
import {
  getAwardDistributedPrizes,
  getAwardPositivationAndTarget,
  getRelatorioHistorico,
} from "../../../store/totalizer/model"
import { dateFormatted } from "../../../utils/date.utils"

function awarddUsecase() {
  const dispatch = useAppDispatch()
  const { error, loading, reports } = useAppSelector((state) => state.home)
  const {
    loading: totalizerLoading,
    dataExtraction,
    awardPositivationAndtarget,
    awardDistributedPrizes,
  } = useAppSelector((state) => state.totalizer)

  const { data: authData } = useAppSelector((state) => state.auth)
  const { dataRelatorioHistorico } = useAppSelector((state) => state.totalizer)

  const [IdRelatorioHistorico, setIdRelatorioHistorico] = useState<number>(0)

  const handleChange = useCallback((value: number) => {
    setIdRelatorioHistorico(value)
  }, [])

  const handleFilter = useCallback(() => {
    dispatch(
      getAwardPositivationAndTarget({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
    dispatch(
      getAwardDistributedPrizes({
        IdRelatorioHistorico,
        userId: authData?.userId,
      }),
    )
  }, [dispatch, IdRelatorioHistorico, authData?.userId])

  const handleCardConsolidatedReport = useCallback(() => {
    //dispatch(getReports({ relatorioBaseTipo: 7 }))
    dispatch(getRelatorioHistorico({}))
  }, [dispatch])

  useEffect(() => {
    const bootstrap = () => {
      dispatch(clearTotalizers())
      handleCardConsolidatedReport()
    }

    return () => {
      bootstrap()
    }
  }, [])

  const data = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.Id} ${item.NomeRelatorioTipo} (${dateFormatted(
        item.DataInicio,
        "dd/MM/yyyy",
      )} a ${dateFormatted(item.DataCorte, "dd/MM/yyyy")})`,
    }))
  }, [dataRelatorioHistorico])

  return {
    handleChange,
    error,
    loading,
    reports,
    data,
    totalizerLoading,
    handleFilter,
    dataExtraction,
    awardPositivationAndtarget,
    awardDistributedPrizes,
  }
}

export { awarddUsecase }
