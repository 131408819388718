import { createSlice,  } from "@reduxjs/toolkit"

export interface IAuthInitialState {
  message: string;
  type: 'error' | 'success' | 'info'
}

const initialState: IAuthInitialState = {
  message: "",
  type: 'info'
}

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    clearMessage: (state) => {
      state.message = ""
      state.type = 'info'
    },
  }
})

export const { setMessage, clearMessage } = messageSlice.actions

export default messageSlice.reducer
