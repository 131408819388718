import type { ThemeConfig } from "antd"
import { Colors } from "./colors"

const config: ThemeConfig = {
  token: {
    colorPrimary: Colors.primary,
  },
  components: {
    Table: {
      borderColor: Colors.darkGray,
      borderRadius: 0,
      headerBorderRadius: 0,
    },
  },
}

export default config
