import { memo } from "react"
import { Link } from "react-router-dom"
import { Layout } from "antd"
import { MenuProps } from "antd/lib/menu/menu"

import * as S from "./sidenav.styles"
import { Colors } from "../../theme/colors"

import Logo from "../../assets/images/logo.png"

const { Sider } = Layout

function Sidenav({
  selectedKeys = ["0"],
  collapsed,
  setCollapsed,
}: {
  selectedKeys: string[]
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}) {
  const pages: MenuProps["items"] = [
    {
      label: <Link to="/home">Inicio</Link>,
      key: "0",
      icon: null,
    },
    {
      label: "Exportar Bases",
      key: "1",
      icon: null,
      children: [
        {
          label: <Link to="/exportar/consolidado">Consolidado</Link>,
          key: "1.1",
          icon: null,
        },
        {
          label: <Link to="/exportar/base-meta-pontuacao">Base Meta Pontuação</Link>,
          key: "1.2",
          icon: null,
        },
        {
          label: <Link to="/exportar/base-volume-positivacao-mensal">Base Volume Positivação Mensal</Link>,
          key: "1.3",
          icon: null,
        },
        {
          label: <Link to="/exportar/base-volume-positivacao-total">Base Volume Positivação Total</Link>,
          key: "1.4",
          icon: null,
        },
        {
          label: <Link to="/exportar/vinculo-cliente-vendedor">Vínculo Cliente Vendedor</Link>,
          key: "1.6",
          icon: null,
        },
        {
          label: <Link to="/exportar/vinculo-vendedor-supervisor">Vínculo Vendedor Supervisor</Link>,
          key: "1.5",
          icon: null,
        },
        // {
        //   label: (
        //     <Link to="/exportar/ranking-vendedores-mes">
        //       Ranking Vendedores Mês
        //     </Link>
        //   ),
        //   key: "1.5",
        //   icon: null,
        // },
        // {
        //   label: (
        //     <Link to="/exportar/ranking-vendedores-trimestre">
        //       Ranking Vendedores Trimeste
        //     </Link>
        //   ),
        //   key: "1.6",
        //   icon: null,
        // },
        // {
        //   label: (
        //     <Link to="/exportar/ranking-supervisores-mes">
        //       Ranking Supervisores Mês
        //     </Link>
        //   ),
        //   key: "1.3",
        //   icon: null,
        // },
        // {
        //   label: (
        //     <Link to="/exportar/ranking-supervisores-trimestre">
        //       Ranking Supervisores Trimeste
        //     </Link>
        //   ),
        //   key: "1.4",
        //   icon: null,
        // },
        // {
        //   label: (
        //     <Link to="/exportar/ranking-gerentes-coordenadores">
        //       Ranking Gerentes Coordenadores
        //     </Link>
        //   ),
        //   key: "1.2",
        //   icon: null,
        // },
      ],
    },
    {
      label: "Totalizadores",
      key: "2",
      icon: null,
      children: [
        {
          label: <Link to="/totalizadores/card">Card</Link>,
          key: "2.1",
          icon: null,
        },
        {
          label: <Link to="/totalizadores/kpi">KPIs</Link>,
          key: "2.2",
          icon: null,
        },
        {
          label: <Link to="/totalizadores/aceleradores">Aceleradores</Link>,
          key: "2.3",
          icon: null,
        },
        {
          label: <Link to="/totalizadores/premiacoes">Premiações</Link>,
          key: "2.4",
          icon: null,
        },
      ],
    },
    {
      label: <Link to="/importar-bases">Importar Bases</Link>,
      key: "3",
      icon: null,
    },
  ]

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{
        backgroundColor: Colors.primary,
      }}
      onCollapse={(value) => setCollapsed(value)}
      width={300}
      collapsed={collapsed}
      collapsible={true}
      trigger={null}
    >
      <S.LogoContainer>
        <S.Logo src={Logo} alt="logo" />
      </S.LogoContainer>
      <S.CustomMenu
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        items={pages}
      />
    </Sider>
  )
}

export default memo(Sidenav)
