import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../services/base.service"
import {
  IReportRequest,
  IReportResponse,
  IResponseFilterData,
  IReportRequestPatch,
} from "./interfaces"

export const getReports = createAsyncThunk<IReportResponse, IReportRequest>(
  "home/report",
  async (params) => {
    const response = await api.get<IReportResponse>("/report/consolidated", {
      params,
    })
    return response.data
  },
)

export const getReportData = createAsyncThunk<IReportResponse, IReportRequest>(
  "home/reportData",
  async (params) => {
    const response = await api.get<IReportResponse>("/report/report-data", {
      params,
    })
    return response.data
  },
)

export const getReportFilterData = createAsyncThunk<
  IResponseFilterData,
  undefined
>("home/reportFilterData", async () => {
  const response = await api.get<IResponseFilterData>(
    "/report/consolidated/filter-data",
  )
  return response.data
})

export const patchReports = createAsyncThunk<
  IReportResponse,
  IReportRequestPatch
>("home/reportPatch", async (params) => {
  const response = await api.patch<IReportResponse>(
    "/consolidated/report-generated",
    params,
  )
  return response.data
})
