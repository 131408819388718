import { useCallback, useState, useEffect, useMemo } from "react"
import api from "../../../services/base.service"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  getCardTotalizerData,
  getDataExtract,
  getRelatorioHistorico,
} from "../../../store/totalizer/model"
import { patchReports } from "../../../store/home/model"
import { clearTotalizers } from "../../../store/totalizer/totatilzer.slice"
import { dateFormatted } from "../../../utils/date.utils"
import { IExtractDataReport } from "./interfaces"

function cardUsecase() {
  const dispatch = useAppDispatch()

  const { error, loading, reports } = useAppSelector((state) => state.home)

  const { data: authData } = useAppSelector((state) => state.auth)

  const { dataRelatorioHistorico } = useAppSelector((state) => state.totalizer)

  const {
    loading: totalizerLoading,
    card,
    dataExtraction,
  } = useAppSelector((state) => state.totalizer)

  const [IdRelatorioHistorico, setIdRelatorioHistorico] = useState<number>(0)

  useEffect(() => {
    dispatch(clearTotalizers())
  }, [])

  const handleChange = useCallback((value: number) => {
    setIdRelatorioHistorico(value)
  }, [])

  const handleFilter = useCallback(() => {
    dispatch(
      getCardTotalizerData({ IdRelatorioHistorico, userId: authData?.userId }),
    )
  }, [dispatch, IdRelatorioHistorico, authData?.userId])

  const handleCardConsolidatedReport = useCallback(
    (reportName: string) => {
      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const fileName = `${year}${month}${day}${hours}${minutes}` + reportName

      dispatch(
        patchReports({
          relatorioBaseTipo: 7,
          IdRelatorioHistorico,
          userId: authData?.userId,
          fileName,
        }),
      )
    },
    [dispatch, IdRelatorioHistorico, authData?.userId],
  )

  // idUser: string;
  //     fileName: string;

  const downloadReport = useCallback(async (item: IExtractDataReport) => {
    try {
      const response = await api.get(`report/consolidated/report`, {
        params: {
          IdRelatorioHistorico: item.IdRelatorioHistorico,
          nomeArquivo: item.Arquivo ? item.Arquivo : "relatorio.xlsx",
        },
        responseType: "blob",
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", item.Arquivo)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    const bootstrap = () => {
      handleCardConsolidatedReport("reportName")
      dispatch(getDataExtract({ relatorioBaseTipo: 7 }))
      dispatch(getRelatorioHistorico({}))
    }

    return () => {
      bootstrap()
    }
  }, [])

  const data = useMemo(() => {
    return dataRelatorioHistorico?.map((item) => ({
      value: item.Id,
      label: `${item.Id} ${item.NomeRelatorioTipo} (${dateFormatted(
        item.DataInicio,
        "dd/MM/yyyy",
      )} a ${dateFormatted(item.DataCorte, "dd/MM/yyyy")})`,
    }))
  }, [dataRelatorioHistorico])

  return {
    handleChange,
    handleFilter,
    handleCardConsolidatedReport,
    downloadReport,
    error,
    loading,
    reports,
    data,
    totalizerLoading,
    card,
    dataExtraction,
  }
}

export { cardUsecase }
