import { createSlice } from "@reduxjs/toolkit"
import {
  importClientVipizz,
  importCliven,
  importQlik,
  importRescues,
  importSalesTeam,
  importSalesTeamHierarchy,
  importSiteSignup,
  importSiteSignupDate,
  importTarget,
} from "./models"

interface IBase {
  label: string
  status: string
  loading: boolean
}

interface IImportReport {
  reports: IBase[]
}

const initialState: IImportReport = {
  reports: [
    {
      label: "Qlik",
      status: "",
      loading: false,
    },
    {
      label: "Equipe Vendas",
      status: "",
      loading: false,
    },
    {
      label: "Cadastro Site",
      status: "",
      loading: false,
    },
    {
      label: "Data Cadastro Site",
      status: "",
      loading: false,
    },
    {
      label: "Cliente Vipizz",
      status: "",
      loading: false,
    },
    {
      label: "Cliven",
      status: "",
      loading: false,
    },
    {
      label: "Meta Volume",
      status: "",
      loading: false,
    },
    {
      label: "Resgate",
      status: "",
      loading: false,
    },
    {
      label: "Time Vendas Hierarquia",
      status: "",
      loading: false,
    },
  ],
}

export const importSlice = createSlice({
  name: "imports",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(importSalesTeam.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Equipe Vendas") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importSalesTeam.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Equipe Vendas") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importSalesTeam.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Equipe Vendas") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importQlik.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Qlik") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importQlik.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Qlik") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importQlik.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Qlik") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importSalesTeamHierarchy.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Time Vendas Hierarquia") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importSalesTeamHierarchy.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Time Vendas Hierarquia") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importSalesTeamHierarchy.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Time Vendas Hierarquia") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importRescues.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Resgate") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importRescues.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Resgate") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importRescues.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Resgate") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importTarget.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Meta Volume") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importTarget.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Meta Volume") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importTarget.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Meta Volume") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importSiteSignupDate.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Data Cadastro Site") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importSiteSignupDate.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Data Cadastro Site") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importSiteSignupDate.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Data Cadastro Site") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importCliven.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cliven") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importCliven.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cliven") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importCliven.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cliven") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importClientVipizz.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Clientes Vipizz") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importClientVipizz.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Clientes Vipizz") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importClientVipizz.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Clientes Vipizz") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
      .addCase(importSiteSignup.pending, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cadastro Site") {
            state.reports[index].loading = true
            state.reports[index].status = "importando"
          }
        })
      })
      .addCase(importSiteSignup.fulfilled, (state, action) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cadastro Site") {
            state.reports[index].loading = false
            state.reports[index].status = action.payload.data
          }
        })
      })
      .addCase(importSiteSignup.rejected, (state) => {
        state.reports.forEach((report, index) => {
          if (report.label === "Cadastro Site") {
            state.reports[index].loading = false
            state.reports[index].status = 'import com erro'
          }
        })
      })
  },
})

export default importSlice.reducer
